import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Box, Grid, Typography } from '@mui/material';
import { useUserContext } from 'components';
import useTableParams from 'hooks/useTableParams/useTableParams';
import { collections as collectionsApi } from 'services/api';
import { TABLE_PARAMS_IDS } from 'src/constants/constants';

import Loading from '../../Loading/Loading';
import ActivityLogTable from '../ActivityLogTable/ActivityLogTable';
import ProviderAutocomplete from '../ProviderAutoComplete/ProviderAutoComplete';

import styles from './collectionsActivityLog.module.scss';

const STATUS = {
    LOADING: 'LOADING',
    LOADED: 'LOADED',
    ERROR: 'ERROR',
};

const DEFAULT_TABLE_PARAMETERS = {
    offset: 0,
    limit: 10,
    selectedProvider: { label: 'All' },
};

const sortAlphabetically = providers => [
    { label: 'All' },
    ...providers
        .map(provider => ({
            ...provider,
            label: `${provider.name} ${provider.institutionId}`,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
];

/* Renders when logged in as hesa admin
 -> click on a collection -> click on three little dots next to upload -> click on activity log
 */
const CollectionsActivityLog = ({ providers }) => {
    const { reference } = useParams();
    const { activeOrganisation } = useUserContext();
    const [activityLog, setActivityLog] = useState([]);
    const [status, setStatus] = useState(STATUS.LOADING);

    const tableId = TABLE_PARAMS_IDS.COLLECTIONS_ACTIVITY_LOG;
    const { values: tableParams, methods: tableParamsMethods } = useTableParams(
        tableId,
        DEFAULT_TABLE_PARAMETERS,
    );

    const { limit, offset, selectedProvider } = tableParams;
    const { setLimit, setOffset, setSelectedProvider } = tableParamsMethods;

    const fetchData = useCallback(
        async (pageOverride, selectedProviderOverride) => {
            try {
                const page =
                    pageOverride >= 0 ? pageOverride * limit : offset * limit;
                const providerToLoad =
                    selectedProviderOverride ?? selectedProvider;
                const fetchedActivityLog = await collectionsApi.getActivityLog({
                    collectionReference: reference,
                    limit,
                    offset: page,
                    ...(providerToLoad?.institutionId && {
                        instId: providerToLoad.institutionId,
                    }),
                });

                const enrichedActivityLog = {
                    ...fetchedActivityLog,
                    records: fetchedActivityLog.records.map(log => ({
                        ...log,
                        providerName:
                            providers.find(
                                provider =>
                                    provider.institutionId === log.instId,
                            )?.name ?? '-',
                    })),
                };

                setActivityLog(enrichedActivityLog);
                setStatus(STATUS.LOADED);
            } catch (error) {
                setStatus(STATUS.ERROR);
            }
        },
        [limit, offset, providers, reference, selectedProvider],
    );

    useEffect(() => {
        fetchData();
    }, [fetchData, activeOrganisation.id]);

    const handleChangeRowsPerPage = event => {
        setLimit(Number(event.target.value));
        setOffset(0);
    };

    const handleChangePage = (_, newOffset) => {
        setOffset(newOffset);
    };

    const handleProviderChange = (_, newValue) => {
        setSelectedProvider(newValue);
        setOffset(0);
        fetchData(0, newValue);
    };

    if (status === STATUS.LOADING) return <Loading />;

    if (status === STATUS.ERROR) {
        return (
            <Box mt={3}>
                <Typography>
                    Could not load Activity Log, please contact Jisc
                    Administrator.
                </Typography>
            </Box>
        );
    }

    if (!activityLog || !activityLog.records) {
        return (
            <Typography className={styles.noResults}>
                No results found.
            </Typography>
        );
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <div className={styles.actionsBar}>
                    <ProviderAutocomplete
                        providers={sortAlphabetically(providers)}
                        selectedProvider={selectedProvider}
                        onProviderChange={handleProviderChange}
                    />
                </div>
                <ActivityLogTable
                    kind="collection"
                    activityLogEntries={activityLog}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handleChangePage={handleChangePage}
                />
            </Grid>
        </Grid>
    );
};

export default CollectionsActivityLog;
