import { stringify } from 'query-string';
import { Collection } from 'types/collection';
import { RefDataProvider } from 'types/provider';

import { get, post } from '../utils';

import {
    GetProviderActivityLogRequestArgs,
    GetProviderActivityLogResponse,
} from './types';

export const getActivityLog = ({
    instId,
    collectionReference,
    offset,
    limit,
}: GetProviderActivityLogRequestArgs) =>
    get(
        `/providers/${instId}/activity-log?collectionReference=${collectionReference}&offset=${offset}&limit=${limit}`,
    ).then(res => res) as Promise<GetProviderActivityLogResponse>;

export const getAll = () =>
    get(`/providers`).then(providers => ({ providers })) as Promise<{
        providers: RefDataProvider[];
    }>;

export const getCollections = ({
    instId,
    state = [],
}: {
    instId?: string;
    state: string[];
}) =>
    get(`/providers/${instId}/collections?${stringify({ state })}`).then(
        res => res.collections,
    ) as Promise<Collection[]>;

export const onboard = ({ instId }: { instId: string }) =>
    post('/providers', {
        body: { instId },
        headers: {},
    }) as Promise<Record<string, never>>; // Just returns an empty object
