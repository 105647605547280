import React from 'react';
import GhostButton from 'components/Button/GhostButton';

import AwaitingActionsFilter from './AwaitingActionsFilter';
import ProviderFilter from './ProviderFilter';
import RegulatorFilter from './RegulatorFilter';
import StateFilter from './StateFilter';

import styles from './filters.module.scss';

export interface AwaitingParty {
    id: string;
    name: string;
}
export interface SubmissionWithState {
    provider: {
        name: string;
        institutionId: string;
        regulatorCode: string;
    };
    latestSubmission: {
        status: {
            step: {
                name: string;
            };
        };
    };
    awaitingParty: AwaitingParty | null;
    state: number;
}
export interface Provider {
    institutionId: string;
    name: string;
}

interface FiltersProps {
    providers: Provider[];
    submissions: SubmissionWithState[];
    setSelectedProviders: (value: (string | number)[]) => void;
    setSelectedStatuses: (value: (string | number)[]) => void;
    selectedProviders: (string | number)[];
    selectedStatuses: (string | number)[];
    selectedRegulators: (string | number)[];
    setSelectedRegulators: (value: (string | number)[]) => void;
    selectedAwaitingAction: (string | number)[];
    setSelectedAwaitingAction: (value: (string | number)[]) => void;
    isOvt?: boolean;
}

export default function Filters({
    providers,
    submissions,
    setSelectedProviders,
    setSelectedStatuses,
    selectedProviders,
    selectedStatuses,
    selectedRegulators,
    setSelectedRegulators,
    selectedAwaitingAction,
    setSelectedAwaitingAction,
    isOvt = false,
}: FiltersProps) {
    const handleClearAllFilters = () => {
        if (!isOvt) setSelectedAwaitingAction([]);
        setSelectedProviders([]);
        setSelectedRegulators([]);
        setSelectedStatuses([]);
    };

    const handleProviderChange = (
        _: React.SyntheticEvent,
        value: (string | number)[],
    ) => {
        setSelectedProviders(value);
    };

    const handleStateChange = (
        _: React.SyntheticEvent,
        value: (string | number)[],
    ) => {
        setSelectedStatuses(value);
    };

    const handleRegulatorChange = (
        _: React.SyntheticEvent,
        value: (string | number)[],
    ) => {
        setSelectedRegulators(value);
    };

    const handleAwaitingActionChange = (
        _: React.SyntheticEvent,
        value: (string | number)[],
    ) => {
        setSelectedAwaitingAction(value);
    };

    return (
        <div className={styles.filters}>
            <ProviderFilter
                providers={providers}
                onChange={handleProviderChange}
                value={selectedProviders}
            />
            <StateFilter
                submissions={submissions}
                onChange={handleStateChange}
                value={selectedStatuses}
            />
            <RegulatorFilter
                value={selectedRegulators}
                submissions={submissions}
                onChange={handleRegulatorChange}
            />
            {!isOvt && (
                <AwaitingActionsFilter
                    value={selectedAwaitingAction}
                    submissions={submissions}
                    onChange={handleAwaitingActionChange}
                />
            )}
            <GhostButton onClick={handleClearAllFilters}>
                Clear all filters
            </GhostButton>
        </div>
    );
}
