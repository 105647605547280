import React, { SyntheticEvent, useState } from 'react';
import {
    AutocompleteChangeReason,
    useAutocomplete,
    UseAutocompleteProps,
    UseAutocompleteReturnValue,
} from '@mui/material';

import CustomInput from './CustomInput/CustomInput';
import OptionsList from './OptionsList/OptionsList';

import styles from './autocompleteDropdown.module.scss';

export interface AutocompleteOption {
    value: string | number;
    label: string;
}

interface AutocompleteDropdownProps {
    options: AutocompleteOption[];
    value: (string | number)[];
    id: string;
    onChange: (
        event: React.SyntheticEvent,
        value: (string | number)[],
        reason: AutocompleteChangeReason,
    ) => void;
    placeholder?: string;
    disabled?: boolean;
}

function filterOptionsByInputValue(
    options: AutocompleteOption[],
    inputValue: string,
) {
    return options.filter(option =>
        option.label.toLowerCase().includes(inputValue.toLowerCase()),
    );
}

export default function AutocompleteDropdown({
    options: optionsProp,
    value = [],
    id,
    onChange,
    placeholder,
    disabled,
}: AutocompleteDropdownProps) {
    const [inputValue, setInputValue] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const handleToggleSelectAll = (
        event: SyntheticEvent,
        reason: AutocompleteChangeReason,
    ) => {
        const newValue =
            value.length === optionsProp.length
                ? []
                : optionsProp.map(option => option.value);
        onChange(event, newValue, reason);
    };

    const handleChange = (
        event: SyntheticEvent,
        newValue: (string | number)[],
        reason: AutocompleteChangeReason,
    ) => {
        if (newValue.includes('Select all')) {
            handleToggleSelectAll(event, reason);
        } else {
            onChange(event, newValue, reason);
        }
    };

    const {
        getRootProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
        setAnchorEl,
        focused,
        expanded,
        getClearProps,
    } = useAutocomplete({
        id,
        options: ['Select all', ...optionsProp.map(option => option.value)],
        value,
        multiple: true,
        inputValue,
        onInputChange: (_, newInputValue, reason) => {
            if (reason !== 'reset') {
                setInputValue(newInputValue);
            }
        },
        open: isOpen,
        onOpen: () => setIsOpen(true),
        onClose: () => setIsOpen(false),
        onChange: handleChange as UseAutocompleteProps<
            string | number,
            true,
            false,
            false
        >['onChange'],
        disableCloseOnSelect: true,
        filterOptions: (_, state) => {
            const { inputValue } = state;
            const filteredOptions = filterOptionsByInputValue(
                optionsProp,
                inputValue,
            ).map(option => option.value);
            return ['Select all', ...filteredOptions];
        },
        disabled,
    });

    const isAllSelected = value.length === optionsProp.length;

    const options: AutocompleteOption[] = [
        { label: 'Select all', value: 'Select all' },
        ...optionsProp,
    ];

    const handleClearClick = () => {
        const clearProps = getClearProps();
        if (clearProps.onClick) {
            clearProps.onClick({} as React.MouseEvent<HTMLButtonElement>);
        }
    };

    const typedGetOptionProps = getOptionProps as UseAutocompleteReturnValue<
        string,
        true,
        false,
        false
    >['getOptionProps'];

    return (
        <div className={styles.container}>
            <div {...getRootProps()}>
                <CustomInput
                    getInputProps={getInputProps}
                    inputValue={inputValue}
                    focused={focused}
                    expanded={expanded}
                    setAnchorEl={setAnchorEl}
                    setIsOpen={setIsOpen}
                    isOpen={isOpen}
                    value={value}
                    placeholder={placeholder ?? ''}
                    handleClearClick={handleClearClick}
                />
            </div>
            {groupedOptions.length > 0 && (
                <OptionsList
                    groupedOptions={groupedOptions}
                    options={options}
                    id={id}
                    isAllSelected={isAllSelected}
                    getOptionProps={renderedOption => ({
                        ...typedGetOptionProps(renderedOption),
                        key: renderedOption.option,
                    })}
                    value={value}
                    getListboxProps={getListboxProps}
                />
            )}
        </div>
    );
}
