import { useMemo } from 'react';
import {
    getUiState,
    uiStates,
    useProviderStatuses,
    useSubmissionStates,
} from 'src/pages/Monitoring/queries';

const transformToSteps = states => {
    const steps = states.reduce((acc, { step }) => {
        return { ...acc, [step.number]: { ...step, count: 0 } };
    }, {});

    return Object.values(steps);
};

const addTotalSteps = steps => providerStatus => {
    return {
        ...providerStatus,
        totalSteps: steps.length,
    };
};

const transformToAwaitingParties = states => {
    return states.reduce((output, state) => {
        return {
            ...output,
            [state.id]: state.awaitingParty,
        };
    }, {});
};

const addAwaitingParty = awaitingParties => providerStatus => {
    return {
        ...providerStatus,
        awaitingParty: providerStatus.latestSubmission
            ? awaitingParties[providerStatus.latestSubmission?.status.id]
            : { id: 1, name: 'Provider' },
    };
};

const pipe =
    (...fns) =>
    x =>
        fns.reduce((v, f) => f(v), x);

export const useProviderSubmissions = ({ collectionReference }) => {
    const providerStatuses = useProviderStatuses(collectionReference);
    const submissionStates = useSubmissionStates();

    const status = useMemo(
        () => getUiState([providerStatuses, submissionStates]),
        [providerStatuses, submissionStates],
    );

    const submissions = useMemo(() => {
        return status === uiStates.LOADED
            ? providerStatuses.data.map(
                  pipe(
                      addTotalSteps(transformToSteps(submissionStates.data)),
                      addAwaitingParty(
                          transformToAwaitingParties(submissionStates.data),
                      ),
                  ),
              )
            : [];
    }, [providerStatuses, submissionStates, status]);

    return {
        submissions,
    };
};
