import type { LabelHTMLAttributes, PropsWithChildren } from 'react';
import classNames from 'classnames';

import styles from './label.module.scss';

export type LabelProps = {
    size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
} & LabelHTMLAttributes<HTMLLabelElement>;
const Label = ({ children, size }: PropsWithChildren<LabelProps>) => {
    return (
        <label className={classNames([styles.label], [styles[size]])}>
            {children}
        </label>
    );
};

Label.displayName = 'Label';

export default Label;
