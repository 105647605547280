import {
    generatePath,
    ParamParseKey,
    Params,
    redirect,
} from 'react-router-dom';

type InferredPathParams<Path extends string> = {
    [key in ParamParseKey<Path>]: string | null;
};

export function generatePathWithInferredParams<Path extends string>(
    path: Path,
    params: Params,
) {
    const inferredParams = params as InferredPathParams<Path>;
    return generatePath(path, inferredParams);
}

export function redirectToPathWithParams(
    this: any,
    path: string,
    params: Params,
) {
    const pathWithParams = generatePathWithInferredParams(path, params);
    return redirect(pathWithParams);
}
