import React, { ReactNode, useContext } from 'react';
import { APP_NAME } from 'src/constants/constants';

import { PageTitleContext } from './Context';

export const usePageTitle = (title: string) => {
    const { updateTitle } = useContext(PageTitleContext);
    updateTitle(title);
};

export const PageTitleProvider = ({ children }: { children: ReactNode }) => {
    const updateTitle = (title: string) => {
        document.title = `${title} | ${APP_NAME}`;
    };
    return (
        <PageTitleContext.Provider value={{ updateTitle }}>
            {children}
        </PageTitleContext.Provider>
    );
};
