import { ReactComponent as DownloadIcon } from 'assets/icons/download_icon.svg';
import { formatTestId } from 'utils/formatTestId/formatTestId';

import styles from './downloadButton.module.scss';

function getDataTestIdFromStepName(stepName: string): string {
    if (stepName === 'Enrichment') {
        return formatTestId('download', 'enriched file');
    } else if (stepName === 'Schema check') {
        return formatTestId('download', 'schema errors file');
    }
    return '';
}

function getAriaLabelFromStepName(stepName: string): string {
    if (stepName === 'Enrichment') {
        return 'Download enriched file';
    } else if (stepName === 'Schema check') {
        return 'Download schema errors file';
    }
    return '';
}
interface DownloadButtonProps {
    stepName: string;
    onClick: () => void;
}

const DownloadButton = ({ stepName, onClick }: DownloadButtonProps) => {
    const dataTestId = getDataTestIdFromStepName(stepName);
    const ariaLabel = getAriaLabelFromStepName(stepName);

    return (
        <DownloadIcon
            tabIndex={0}
            role="button"
            data-test-id={dataTestId}
            className={styles.fileDownload}
            aria-label={ariaLabel}
            onFocus={e => e.stopPropagation()}
            onClick={onClick}
        />
    );
};

export default DownloadButton;
