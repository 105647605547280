import { Box, type BoxProps, Typography } from '@mui/material';

import styles from './accessDenied.module.scss';

const AccessDenied = (props: BoxProps) => {
    return (
        <Box className={styles.accessDenied} {...props}>
            <Typography variant={'h6'} color={'error'} paragraph>
                Access Denied
            </Typography>
            <Typography variant={'body1'} color={'textSecondary'}>
                You do not have a valid role to view the content on this page.
            </Typography>
        </Box>
    );
};

AccessDenied.displayName = 'AccessDenied';

export default AccessDenied;
