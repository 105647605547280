import {
    AccessTokenCallback,
    UserManager,
    WebStorageStateStore,
} from 'oidc-client-ts';
import {
    AUTH_CLIENT_ID,
    AUTH_CLIENT_URL,
    AUTH_REDIRECT_HOST,
} from 'src/constants/constants';

const manager = new UserManager({
    authority: AUTH_CLIENT_URL,
    client_id: AUTH_CLIENT_ID,
    redirect_uri: `${AUTH_REDIRECT_HOST}/callback/signin`,
    post_logout_redirect_uri: `${AUTH_REDIRECT_HOST}/callback/signout`,
    response_type: 'code',
    scope: 'openid email exchange:token offline_access',
    loadUserInfo: true,
    userStore: new WebStorageStateStore({
        prefix: 'ids.',
        store: window.localStorage,
    }),
    accessTokenExpiringNotificationTimeInSeconds: 60,
    automaticSilentRenew: true,
});

export const getUser = async () => manager.getUser();

export const signIn = async (state: unknown) =>
    manager.signinRedirect({ state });

export const signOut = async () => {
    await manager.clearStaleState();
    return manager.signoutRedirect();
};

export const signInSilent = async () => manager.signinSilent();

export const signinCallback = async () => manager.signinRedirectCallback();

export const signoutCallback = async () => manager.signoutRedirectCallback();

export const getTokenExpiredEvents = async (callback: AccessTokenCallback) =>
    manager.events.addAccessTokenExpired(callback);

export const getTokenExpiringEvents = (callback: AccessTokenCallback) =>
    manager.events.addAccessTokenExpiring(callback);
