import { Regulator } from 'types/regulator';

export const checkIfRegulatorIsPrimaryRegulator = (type: string) =>
    type === regulatorTypes.PRIMARY_REGULATOR;

export const regulatorTypes = {
    PRIMARY_REGULATOR: '01',
    SPECIALIST: '02',
    INTERNAL: '03',
    OTHER: '04',
};

export const getRegulatorShortName = (
    code: string,
    regulators?: Regulator[],
): string | undefined => {
    return regulators?.find(regulator => regulator.regulatorCode === code)
        ?.shortName;
};
