import { keepPreviousData, queryOptions } from '@tanstack/react-query';
import { providers } from 'services/api';
import { GetProviderActivityLogRequestArgs } from 'services/api/providers/types';

export const getUseProvidersQueryOptions = () => {
    return queryOptions({
        queryKey: ['providers', 'list'],
        queryFn: () => providers.getAll(),
    });
};

export const getUseProviderActivityLogQueryOptions = (
    args: GetProviderActivityLogRequestArgs,
) => {
    return queryOptions({
        queryKey: ['providers', 'activity-log', args],
        queryFn: () => providers.getActivityLog(args),
        enabled: Boolean(args.instId) && Boolean(args.collectionReference),
        placeholderData: keepPreviousData,
    });
};

export const getUseProviderCollectionsQueryOptions = (
    instId?: string,
    state: string[] = [],
) => {
    return queryOptions({
        queryKey: ['providers', 'collections', { instId, state }],
        queryFn: () => providers.getCollections({ instId, state }),
        enabled: Boolean(instId),
    });
};
