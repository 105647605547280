import { defaultValidationGuidanceUrl } from 'constants/constants';
import { checkIfRegulatorIsPrimaryRegulator } from 'queries/regulator/utils';

import {
    ConjoinedTolerance,
    RegulatorForEdit,
    RegulatorInterface,
    RegulatorTolerance,
    SpecificationInterface,
    Tolerance,
    ValidationInterface,
} from './types';

export const getSpecificationVersions = (
    specifications: SpecificationInterface[],
) => {
    return specifications.map(({ id, name }) => ({ id, name }));
};

const getToleranceText = (tolerance: Tolerance) => {
    const { toleranceTypeId, toleranceFrom, toleranceTo } = tolerance;

    const toleranceTypeIds = {
        count: 1,
        percentage: 2,
        range: 3,
    };

    switch (toleranceTypeId) {
        case toleranceTypeIds.count:
            return `${toleranceFrom}`;

        case toleranceTypeIds.percentage:
            return `${toleranceFrom.toFixed(2)}%`;

        case toleranceTypeIds.range:
            return `${toleranceFrom} to ${toleranceTo}`;

        default:
            return 'Invalid tolerance type';
    }
};

export const getRegulatorTolerance = (
    validation: ValidationInterface,
): RegulatorTolerance[] => {
    const { regulators } = validation;

    const byToleranceTypeId = (a: Tolerance, b: Tolerance): number => {
        return a.toleranceTypeId - b.toleranceTypeId;
    };

    const sortTolerances = (regulator: ConjoinedTolerance) => ({
        ...regulator,
        tolerances: regulator.tolerances.sort(byToleranceTypeId),
    });

    const addIsPrimary = (
        regulator: ConjoinedTolerance,
    ): ConjoinedTolerance & { isPrimaryRegulator: boolean } => {
        const isPrimaryRegulator: boolean = checkIfRegulatorIsPrimaryRegulator(
            regulator.regulatorTypeCode,
        );

        return { ...regulator, isPrimaryRegulator };
    };

    const byPrimaryThenName = (
        a: ConjoinedTolerance & { isPrimaryRegulator: boolean },
        b: ConjoinedTolerance & { isPrimaryRegulator: boolean },
    ): number => {
        if (a.isPrimaryRegulator === b.isPrimaryRegulator) {
            return a.name.localeCompare(b.name);
        }

        return b.isPrimaryRegulator ? 1 : -1;
    };

    const addTolerance = (
        regulator: ConjoinedTolerance,
    ): ConjoinedTolerance & { tolerance: string } => {
        const toleranceTexts: string[] =
            regulator.tolerances.map(getToleranceText);

        return {
            ...regulator,
            tolerance: toleranceTexts.join(
                getConjunction(regulator.conjunction),
            ),
        };
    };

    const transform = (
        regulator: ConjoinedTolerance & { tolerance: string },
    ): RegulatorTolerance => ({
        code: regulator.regulatorCode,
        name: regulator.name,
        shortName: regulator.shortName,
        tolerance: regulator.tolerance,
    });

    const getConjunction = (letter: string): string => {
        switch (letter) {
            case 'A':
                return ' AND ';
            case 'O':
                return ' OR ';
            default:
                return 'NOT FOUND';
        }
    };

    const groupByRegulatorAndCategory = (
        specificationTolerances: RegulatorInterface[],
    ): ConjoinedTolerance[] => {
        const tolerancesByRegulatorAndCategory =
            specificationTolerances.reduce(
                (
                    output: { [key: string]: ConjoinedTolerance },
                    specificationTolerance,
                ) => {
                    const keyValue =
                        specificationTolerance.regulatorCategoryCode
                            ? `${specificationTolerance.regulatorCode}-${specificationTolerance.regulatorCategoryCode}`
                            : specificationTolerance.regulatorCode;

                    const conjoinedTolerance = output[keyValue] || {
                        regulatorCode: specificationTolerance.regulatorCode,
                        name: specificationTolerance.name,
                        shortName: specificationTolerance.shortName,
                        conjunction: specificationTolerance.conjunction,
                        regulatorTypeLabel:
                            specificationTolerance.regulatorTypeLabel,
                        regulatorTypeCode:
                            specificationTolerance.regulatorTypeCode,
                        tolerances: [],
                    };

                    conjoinedTolerance.tolerances.push({
                        toleranceTypeId: specificationTolerance.toleranceTypeId,
                        toleranceFrom: specificationTolerance.toleranceFrom,
                        toleranceTo: specificationTolerance.toleranceTo,
                    });

                    return { ...output, [keyValue]: conjoinedTolerance };
                },
                {},
            ) || {};

        return Object.values(tolerancesByRegulatorAndCategory) || [];
    };

    const tolerancesByRegulator = groupByRegulatorAndCategory(regulators);

    return tolerancesByRegulator
        .map(sortTolerances)
        .map(addIsPrimary)
        .sort(byPrimaryThenName)
        .map(addTolerance)
        .map(transform);
};

const toleranceTypes = {
    COUNT: 1,
    PERCENTAGE: 2,
    RANGE: 3,
};

export const getRegulatorsForEdit = (
    validation: ValidationInterface,
): RegulatorForEdit[] => {
    const { regulators } = validation || {};

    const getTolerance = (regulator: RegulatorInterface) => {
        switch (regulator.toleranceTypeId) {
            case toleranceTypes.RANGE:
                return {
                    id: regulator.toleranceId,
                    type: regulator.toleranceTypeId,
                    from: regulator.toleranceFrom,
                    to: regulator.toleranceTo,
                };

            default:
                return {
                    id: regulator.toleranceId,
                    type: regulator.toleranceTypeId,
                    value: regulator.toleranceFrom,
                };
        }
    };

    return Object.values(
        regulators.reduce(
            (output: { [key: string]: any }, regulator) => ({
                ...output,
                [regulator.name]: {
                    name: regulator.name,
                    conjunction: regulator.conjunction,
                    tolerances: [
                        ...(output[regulator.name]?.tolerances ?? []),
                        getTolerance(regulator),
                    ].sort((a, b) => a.type - b.type),
                },
            }),
            {},
        ),
    );
};

export const getRuleMetadata = (validation: ValidationInterface) => {
    return {
        description: validation.description,
        guidanceUrl: validation.guidanceUrl || defaultValidationGuidanceUrl,
    };
};
