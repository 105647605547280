import { SyntheticEvent } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import { Provider } from '../types';

interface ProviderAutocompleteProps {
    providers: Provider[];
    selectedProvider: Provider | null;
    onProviderChange: (event: SyntheticEvent, value: Provider | null) => void;
}

const ProviderAutocomplete = ({
    providers,
    selectedProvider,
    onProviderChange,
}: ProviderAutocompleteProps) => (
    <Autocomplete
        value={selectedProvider}
        className="hdp-override"
        disablePortal
        options={providers}
        sx={{ width: 400 }}
        renderInput={params => (
            <TextField
                {...params}
                data-test-id={formatTestId('search', 'activity log')}
                placeholder="Filter by provider"
            />
        )}
        onChange={onProviderChange}
        isOptionEqualToValue={(option, value) => option.name === value.name}
    />
);

export default ProviderAutocomplete;
