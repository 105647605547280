import { useQuery } from '@tanstack/react-query';
import { RefDataProvider } from 'types/provider';

import { getUseProvidersQueryOptions } from './options';

const sortByName = (a: RefDataProvider, b: RefDataProvider) => {
    if (a.publishedName > b.publishedName) return 1;
    if (a.publishedName < b.publishedName) return -1;
    return 0;
};

export default function useProviders() {
    const options = getUseProvidersQueryOptions();
    return useQuery({
        ...options,
        select: data => data.providers.sort(sortByName),
    });
}
