import React from 'react';
import { generatePath, Link, useParams } from 'react-router-dom';
import {
    Chip,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
} from '@mui/material';
import useRegulators from 'queries/regulator/useRegulators';
import { getRegulatorShortName } from 'queries/regulator/utils';
import { useUserContext } from 'src/components/index';
import { OVTPATHS } from 'src/constants/constants';
import {
    calculatePercentage,
    renderStatus,
} from 'src/pages/Collections/Submission/utils';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import styles from '../../../Submission/Steps/Reports/QualityRules/qualityRules.module.scss';

const QualityRuleRow = ({
    rule,
    regulators,
    submissionId,
    instId,
    collection,
}) => {
    const { collectionId, reference } = useParams();
    const { isAdmin, isSpecialCategory } = useUserContext();

    return (
        <>
            <TableCell align="right">
                {getRegulatorShortName(rule.RegulatorCode, regulators)}
            </TableCell>
            <TableCell align="center">
                {rule.ConjoinedTolerances}
                {rule.IsProviderToleranceOverride && (
                    <Chip
                        size="small"
                        label="Override"
                        className={styles.overrideChip}
                    />
                )}
            </TableCell>
            <TableCell align="center">{rule.PopulationCount}</TableCell>
            <TableCell align="center" className={styles.error}>
                <Link
                    className={styles.error}
                    to={generatePath(
                        isAdmin || isSpecialCategory
                            ? OVTPATHS.ADMIN_QUALITY_RULE
                            : OVTPATHS.QUALITY_RULE,
                        {
                            collectionId: collectionId || collection?.id,
                            reference: reference || collection?.reference,
                            submissionId,
                            rule: rule.Code,
                            regulatorCode: rule.RegulatorCode,
                            instId,
                        },
                    )}
                >
                    {rule.FailureCount} (
                    {calculatePercentage(
                        rule.FailureCount,
                        rule.PopulationCount,
                    )}
                    %)
                </Link>
            </TableCell>
        </>
    );
};

const QualityRules = ({
    data,
    collection,
    pagingData,
    collectionId,
    submissionId,
    isLatestSubmission,
    sortHandler,
    pageHandler,
    activeSort,
    activeSortDirection,
    noResultsFound,
    provider,
    reference,
    instId,
}) => {
    const { data: regulators } = useRegulators();

    const handleChangeRowsPerPage = event => pageHandler(0, event.target.value);

    const handleChangePage = (_, page) => pageHandler(page, pagingData.limit);

    const handleHeaderClickedChild = event => {
        const newColumn = event.currentTarget.id;
        let newSortDirection = activeSortDirection;
        if (newColumn === activeSort) {
            if (activeSortDirection === 'asc') {
                newSortDirection = 'desc';
            } else {
                newSortDirection = 'asc';
            }
        }
        sortHandler(newColumn, newSortDirection, 0);
    };

    const handleHeaderKeyDown = event =>
        event.code === 'Enter' || event.code === 'Space'
            ? handleHeaderClickedChild(event)
            : null;

    return !data?.length ? (
        <div className={styles.loading}>
            {!noResultsFound ? (
                <CircularProgress aria-label="Circular loading animation" />
            ) : (
                'No results found for that criteria.'
            )}
        </div>
    ) : (
        <Paper className={styles.qualityRules}>
            <Table data-test-id={formatTestId('table', 'quality rules')}>
                <TableHead>
                    <TableRow className={styles.headerRow}>
                        <TableCell
                            id="ID"
                            onClick={handleHeaderClickedChild}
                            className={`${styles.header} ${styles.interactive}`}
                        >
                            <TableSortLabel
                                active={activeSort === 'ID'}
                                direction={activeSortDirection}
                                className={styles.headerLabel}
                                onKeyDown={handleHeaderKeyDown}
                                data-test-id={formatTestId('sortBy', 'rule')}
                            >
                                Rule
                            </TableSortLabel>
                        </TableCell>
                        <TableCell
                            id="STATUS"
                            align="center"
                            style={{ minWidth: 100 }}
                            className={`${styles.header} ${styles.interactive}`}
                            onClick={handleHeaderClickedChild}
                            onKeyDown={handleHeaderKeyDown}
                            data-test-id={formatTestId('sortBy', 'status')}
                        >
                            <TableSortLabel
                                active={activeSort === 'STATUS'}
                                direction={activeSortDirection}
                                className={styles.headerLabel}
                            >
                                Status
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className={styles.header} align="right">
                            Regulator
                        </TableCell>
                        <TableCell
                            id="TOLERANCE"
                            align="left"
                            style={{ minWidth: 125 }}
                            className={`${styles.header} ${styles.interactive}`}
                            onClick={handleHeaderClickedChild}
                            onKeyDown={handleHeaderKeyDown}
                            data-test-id={formatTestId('sortBy', 'tolerance')}
                        >
                            <TableSortLabel
                                active={activeSort === 'TOLERANCE'}
                                direction={activeSortDirection}
                                className={styles.headerLabel}
                            >
                                Tolerance
                            </TableSortLabel>
                        </TableCell>
                        <TableCell
                            id="POPULATION"
                            align="left"
                            style={{ minWidth: 130 }}
                            className={`${styles.header} ${styles.interactive}`}
                            onClick={handleHeaderClickedChild}
                            onKeyDown={handleHeaderKeyDown}
                            data-test-id={formatTestId('sortBy', 'population')}
                        >
                            <TableSortLabel
                                active={activeSort === 'POPULATION'}
                                direction={activeSortDirection}
                                className={styles.headerLabel}
                            >
                                Population
                            </TableSortLabel>
                        </TableCell>
                        <TableCell
                            id="FAILURE"
                            align="center"
                            style={{ minWidth: 110 }}
                            className={`${styles.header} ${styles.interactive}`}
                            onClick={handleHeaderClickedChild}
                            onKeyDown={handleHeaderKeyDown}
                            data-test-id={formatTestId('sortBy', 'failure')}
                        >
                            <TableSortLabel
                                active={activeSort === 'FAILURE'}
                                direction={activeSortDirection}
                                className={styles.headerLabel}
                            >
                                Failure
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map(row => (
                        <React.Fragment
                            key={`${row.Code}-${row.RegulatorCode}`}
                        >
                            <TableRow hover className={styles.row}>
                                <TableCell>
                                    <b>{row.Code}</b>
                                    <br />
                                    {row.Name}
                                </TableCell>
                                <TableCell align="right">
                                    {renderStatus(row.Status)}
                                </TableCell>
                                <QualityRuleRow
                                    rule={row}
                                    regulators={regulators}
                                    submissionId={submissionId}
                                    isLatestSubmission={isLatestSubmission}
                                    collectionId={collectionId}
                                    provider={provider}
                                    reference={reference}
                                    collection={collection}
                                    instId={instId}
                                />
                            </TableRow>
                            {row.specialistRegulators &&
                                row.specialistRegulators.map(reg => (
                                    <TableRow key={reg.RegulatorCode}>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <QualityRuleRow
                                            rule={reg}
                                            regulators={regulators}
                                            submissionId={submissionId}
                                            isLatestSubmission={
                                                isLatestSubmission
                                            }
                                            instId={instId}
                                            collection={collection}
                                            collectionId={collectionId}
                                            reference={reference}
                                        />
                                    </TableRow>
                                ))}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={pagingData?.totalResultsCount || 0}
                rowsPerPage={pagingData?.limit || 10}
                page={pagingData?.offset / pagingData?.limit || 0}
                backIconButtonProps={{
                    'aria-label': 'previous page',
                    'data-test-id': 'previousPage',
                }}
                nextIconButtonProps={{
                    'aria-label': 'next page',
                    'data-test-id': 'nextPage',
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

export default QualityRules;
