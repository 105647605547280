import { TableCell, TableHead, TableRow } from '@mui/material';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import { COLUMN_NAMES_MAP } from '../../constants';

import styles from './activityLogTableHeader.module.scss';

interface ActivityLogTableHeaderProps {
    kind: 'collection' | 'provider';
}

const ActivityLogTableHeader = ({ kind }: ActivityLogTableHeaderProps) => (
    <TableHead>
        <TableRow
            className={styles.header}
            data-test-id={formatTestId(`${kind} activity log', 'header row`)}
        >
            {COLUMN_NAMES_MAP[kind].map(header => (
                <TableCell key={header} className={styles.bold}>
                    {header}
                </TableCell>
            ))}
        </TableRow>
    </TableHead>
);

export default ActivityLogTableHeader;
