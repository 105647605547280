const remove = (key: string) => () => window.localStorage.removeItem(key);
const get = (key: string) => () => {
    try {
        return JSON.parse(window.localStorage.getItem(key)!);
    } catch (error) {
        window.localStorage.removeItem(key);
        return null;
    }
};
const set = (key: string) => (value: string) =>
    window.localStorage.setItem(key, JSON.stringify(value));

export const token = {
    set: set('hdp.token'),
    get: get('hdp.token'),
    clear: remove('hdp.token'),
};
