import React, { useCallback, useEffect, useRef, useState } from 'react';
import { generatePath, useNavigate, useOutletContext } from 'react-router-dom';
import { Search } from '@mui/icons-material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
    Button,
    DialogContentText,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { RestrictAccess, useUserContext } from 'components';
import AccordionGroup from 'components/AccordionGroup/AccordionGroup';
import { files as filesApi } from 'services/api';
import { getValidations, getValidationsCsv } from 'services/api/submissions';
import Accordion from 'src/components/Accordion/Accordion';
import ConfirmationPopup from 'src/components/ConfirmationPopup/ConfirmationPopup';
import { ACCORDION_IDS, OVTPATHS } from 'src/constants/constants';
import { useStepper } from 'src/pages/Collections/Submission/Steps/Stepper/StepperContext';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';
import { v4 as uuid } from 'uuid';

import { requestAllQualityRuleFailuresDownload } from '../../utils';

import QualityRules from './QualityRules';

import styles from '../../../Submission/Steps/Reports/results.module.scss';

const Results = () => {
    const { data, collection, submissionId } = useOutletContext();
    const [pagingData, setPagingData] = useState([]);

    const { isAdmin } = useUserContext();
    const [validationRows, setValidationRows] = useState([]);

    const [selectedStatus, setSelectedStatus] = useState(1);
    const [validationSortBy, setValidationSortby] = useState('FAILURE');
    const [validationSortOrder, setValidationSortByDirection] =
        useState('desc');
    const [
        showAllQualityRuleFailuresDownloadDialog,
        setShowAllQualityRuleFailuresDownloadDialog,
    ] = useState(false);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const [csvData, setCsvData] = useState();
    const [searchTerm, setSearchTerm] = useState('');
    const [hasErroredFetching, setHasErroredFetching] = useState(false);
    const csvLink = useRef(null);
    const { activeOrganisation } = useUserContext();
    const [allQualityResultsFileExists, setAllQualityResultsFileExists] =
        useState(false);
    const [compare] = useState(false);

    const [noResultsFound, setNoResultsFound] = React.useState(false);
    if (hasErroredFetching) {
        throw new Error('Could not fetch submission validations');
    }

    const history = useNavigate();
    const { navigateToStep } = useStepper();

    const getAllQualityResultsFileExists = async submissionUuid => {
        try {
            const allQualityResultsFileExistsResponse =
                await filesApi.ovtGetAllQualityRuleFailuresExists(
                    submissionUuid,
                );

            setAllQualityResultsFileExists(
                !!allQualityResultsFileExistsResponse.result.fileExists,
            );
        } catch (e) {
            setAllQualityResultsFileExists(false);
        }
    };

    const handleChangeStatus = event => {
        setOffset(0);
        setSelectedStatus(event.target.value);

        setValidationRows([]);
        loadValidationData(
            data.uuid,
            event.target.value,
            validationSortBy,
            validationSortOrder,
            0,
            limit,
            searchTerm,
        );
    };

    const handleSearchSubmit = (updatedSearchTerm = null) => {
        setOffset(0);

        setValidationRows([]);
        loadValidationData(
            data.uuid,
            selectedStatus,
            validationSortBy,
            validationSortOrder,
            0,
            limit,
            updatedSearchTerm ?? searchTerm,
        );
    };

    const handleClearSearch = () => {
        setSearchTerm('');
        handleSearchSubmit('');
    };

    const handleUpdateSearchTerm = event => setSearchTerm(event.target.value);

    const handleSortByClicked = (sortByColumn, sortDirection, page) => {
        setValidationSortby(sortByColumn);
        setValidationSortByDirection(sortDirection);
        setOffset(page);
        setValidationRows([]);
        loadValidationData(
            data.uuid,
            selectedStatus,
            sortByColumn,
            sortDirection,
            page,
            limit,
            searchTerm,
        );
    };

    const handlePageClicked = (page, rowsPage) => {
        setOffset(page);
        setLimit(rowsPage);
        setValidationRows([]);
        loadValidationData(
            data.uuid,
            selectedStatus,
            validationSortBy,
            validationSortOrder,
            page,
            rowsPage,
            searchTerm,
        );
    };

    const loadValidationData = useCallback(
        async (
            _uuid,
            _selectedStatus,
            _validationSortBy,
            _validationSortOrder,
            _offset,
            _limit,
            _searchTerm = '',
        ) => {
            const fetchValidations = async () => {
                try {
                    setNoResultsFound(false);
                    const result = await getValidations({
                        submissionId: _uuid,
                        instId: data.provider
                            ? data.provider.instId
                            : activeOrganisation.id,
                        status: _selectedStatus,
                        searchTerm: _searchTerm,
                        sortBy: _validationSortBy,
                        sortOrder: _validationSortOrder,
                        offset: _offset,
                        limit: _limit,
                        ovt: true,
                    });
                    return result;
                } catch {
                    setHasErroredFetching(true);
                }
            };

            const result = await fetchValidations();

            let primaryResults = result?.records?.filter(
                x => x.IsPrimary === true,
            );
            let specialistResults = result?.records
                ?.filter(x => x.IsPrimary === false)
                .sort((a, b) => (a.RegulatorCode > b.RegulatorCode ? 1 : -1));

            if (primaryResults) {
                primaryResults = primaryResults.map(result => {
                    if (specialistResults) {
                        // Get primary specialist results
                        const primarySpecialistResult =
                            specialistResults.filter(
                                special => special.Code === result.Code,
                            );
                        // Strip sub specials from man specials list
                        specialistResults = specialistResults.filter(
                            special => special.Code !== result.Code,
                        );

                        return {
                            ...result,
                            specialistRegulators: primarySpecialistResult,
                        };
                    }
                    return result;
                });
                primaryResults = [...primaryResults, ...specialistResults];
                setValidationRows(primaryResults);
                setPagingData(result.pagingMetadata);
                if (!result?.records?.length) {
                    setNoResultsFound(true);
                }
            }
        },
        [activeOrganisation, data.provider],
    );

    const handleClickDownloadCsv = async () => {
        if (data.uuid) {
            const csv = await getValidationsCsv({
                submissionId: data.uuid,
                instId: data.provider
                    ? data.provider.instId
                    : activeOrganisation.id,
                status: selectedStatus,
                sortBy: validationSortBy,
                sortOrder: validationSortOrder,
                ovt: true,
            });

            setCsvData({
                href: `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`,
                download: `QualityRuleReport.${data.uuid.toLowerCase()}.${
                    selectedStatus === 0 ? 'inside' : 'outside'
                }.${new Date().toISOString()}.csv`,
            });
        }
    };

    const handleClickAllQualityRuleFailuresDownloadDialogOpen = () => {
        setShowAllQualityRuleFailuresDownloadDialog(true);
    };

    const handleClickAllQualityRuleFailuresDownloadDialogClose = () => {
        setShowAllQualityRuleFailuresDownloadDialog(false);
    };

    const handleClickAllQualityRuleFailuresDownloadDialogContinue =
        async () => {
            setShowAllQualityRuleFailuresDownloadDialog(false);
            if (submissionId) {
                await requestAllQualityRuleFailuresDownload(submissionId);
            }
        };

    const loadQualityRules = () => {
        loadValidationData(
            data.uuid,
            selectedStatus,
            validationSortBy,
            validationSortOrder,
            offset,
            limit,
        );
        getAllQualityResultsFileExists(data.uuid);
    };

    useEffect(() => {
        // Fetch the data on mount.
        loadQualityRules();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (csvLink && csvData) {
            csvLink.current.click();
            setCsvData();
        }
    }, [csvLink, csvData]);

    const newSubmission = () => {
        const newSubmissionRoute = generatePath(
            isAdmin ? OVTPATHS.ADMIN_UPLOAD : OVTPATHS.UPLOAD,
            {
                collectionId: data.collection.id,
                submissionId: uuid().toUpperCase(),
                reference: collection.reference,
            },
        );
        history(newSubmissionRoute);
    };
    return (
        <React.Fragment>
            <ConfirmationPopup
                isOpen={showAllQualityRuleFailuresDownloadDialog}
                onCancel={handleClickAllQualityRuleFailuresDownloadDialogClose}
                onConfirm={
                    handleClickAllQualityRuleFailuresDownloadDialogContinue
                }
                title="Confirmation required!"
                customConfirmButtonText="Download"
            >
                <DialogContentText>
                    <p>
                        Please note that by using the ‘download all’ function,
                        you will extract a data file including any personal data
                        contained within your provider’s latest reports.
                    </p>
                    <p>
                        It is important that when downloading this data, your
                        provider must have in place appropriate processes to
                        ensure that personal data is processed securely, in
                        accordance with Data Protection Legislation (including
                        the provision of the Student Collection Notice) and your
                        agreement(s) with HESA.
                    </p>
                </DialogContentText>
            </ConfirmationPopup>
            <AccordionGroup>
                <Accordion
                    id={ACCORDION_IDS.SUBMISSION.QUALITY_REPORT}
                    title="Quality report"
                    description="Quality report failures in data submission"
                >
                    <div className={styles.qualityReportButtonWrapper}>
                        <RestrictAccess
                            allowPermissions={[
                                'collections.reports-validation-details-ovt',
                            ]}
                        >
                            <Button
                                className={`hdp-override ${styles.qualityReportButton}`}
                                disableRipple={true}
                                disabled={!allQualityResultsFileExists}
                                variant="contained"
                                onClick={
                                    handleClickAllQualityRuleFailuresDownloadDialogOpen
                                }
                                data-test-id={formatTestId(
                                    'downloadDetailedResults',
                                )}
                            >
                                Download Detailed Results
                            </Button>
                        </RestrictAccess>

                        <Button
                            className={`hdp-override ${styles.qualityReportButton}`}
                            disableRipple={true}
                            disabled={compare}
                            variant="contained"
                            onClick={handleClickDownloadCsv}
                            data-test-id={formatTestId(
                                'download',
                                'quality report results',
                            )}
                        >
                            Download Results
                        </Button>
                        {csvData && (
                            <a {...csvData} ref={csvLink}>
                                Download file
                            </a>
                        )}
                    </div>

                    <div className={styles.actionsBarWrapper}>
                        <div className={styles.actionsBar}>
                            <form
                                className={styles.searchInputWrapper}
                                onSubmit={event => {
                                    event.preventDefault(); // stop form submission from refreshing the page
                                    handleSearchSubmit();
                                }}
                            >
                                <TextField
                                    className={styles.searchInput}
                                    id="search-term"
                                    size="small"
                                    value={searchTerm}
                                    variant="outlined"
                                    onChange={handleUpdateSearchTerm}
                                    placeholder="Search"
                                    inputProps={{
                                        'aria-label': 'Search bar',
                                        'data-test-id': formatTestId(
                                            'search input',
                                            'quality rules',
                                        ),
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                onClick={handleClearSearch}
                                                aria-label="Clear search"
                                                data-test-id={formatTestId(
                                                    'clear search',
                                                    'quality rules',
                                                )}
                                            >
                                                <HighlightOffIcon
                                                    style={{
                                                        color: '#23397f',
                                                    }}
                                                />
                                            </IconButton>
                                        ),
                                    }}
                                />
                                <Button
                                    aria-label="search quality rules"
                                    disableRipple={true}
                                    type="submit"
                                    className={styles.searchButton}
                                    data-test-id={formatTestId(
                                        'search button',
                                        'quality rules',
                                    )}
                                >
                                    <Search
                                        fontSize="small"
                                        className={styles.searchButtonIcon}
                                    />
                                </Button>
                            </form>
                            <FormControl
                                className={styles.columnSelectListWrapper}
                            >
                                <Select
                                    className={styles.columnSelectListInner}
                                    value={selectedStatus}
                                    onChange={handleChangeStatus}
                                    data-test-id={formatTestId(
                                        'open menu',
                                        'tolerance',
                                    )}
                                >
                                    <MenuItem
                                        value="1"
                                        data-test-id={formatTestId(
                                            'option',
                                            'outside tolerance',
                                        )}
                                    >
                                        Outside tolerance
                                    </MenuItem>
                                    <MenuItem
                                        value="0"
                                        data-test-id={formatTestId(
                                            'open',
                                            'inside tolerance',
                                        )}
                                    >
                                        Inside tolerance
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div style={{ flexDirection: 'column' }}>
                        <QualityRules
                            collection={collection}
                            collectionId={
                                data.collectionId || data.collection.id
                            }
                            instId={data?.provider?.instId}
                            submissionId={data.uuid}
                            isLatestSubmission={data.isLatest}
                            data={validationRows}
                            pagingData={pagingData}
                            sortHandler={handleSortByClicked}
                            pageHandler={handlePageClicked}
                            activeSort={validationSortBy}
                            noResultsFound={noResultsFound}
                            activeSortDirection={validationSortOrder}
                            provider={
                                data.provider
                                    ? data.provider.instId
                                    : activeOrganisation.id
                            }
                            reference={collection?.reference}
                        />
                    </div>
                </Accordion>
            </AccordionGroup>

            <div className={styles.buttons}>
                <Button
                    className="hdp-override large hdp-override--grey"
                    data-test-id="back"
                    disabled={false}
                    disableRipple={true}
                    variant="contained"
                    onClick={() => navigateToStep('Processing')}
                >
                    Back
                </Button>
                <RestrictAccess
                    allowPermissions={['online-validation.online-validation']}
                >
                    <Button
                        className={`hdp-override large hdp-override--grey ${styles.uploadButton}`}
                        data-test-id={formatTestId('upload', 'new file')}
                        disableRipple={true}
                        variant="contained"
                        onClick={newSubmission}
                    >
                        Upload new file
                    </Button>
                </RestrictAccess>
            </div>
        </React.Fragment>
    );
};

export default Results;
