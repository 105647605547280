export const AL_UPLOADING_VIA_UI = 1;
export const AL_UPLOADED_VIA_UI = 2;
export const AL_UPLOAD_ERROR = 3;
export const AL_SUBMIT_FOR_APPROVAL = 4;
export const AL_HESA_ANALYST_APPROVAL = 5;
export const AL_HESA_APPROVAL = 6;
export const AL_SC_APPROVAL = 7;
export const AL_SIGNED_OFF = 8;
export const AL_NIL_RETURN = 10;
export const AL_CREDIBILITY_REQUESTED = 16;
