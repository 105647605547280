export const fonts = {
    regularFont: '"Arial", sans-serif',
};

export const colors = {
    geyser: '#d4e2e8',
    shipCove: '#6e87bb',
    blue: '#006dff',
    accessibility: '#0367ee',
    solitude: '#edf4fb',
    mikeBlue: '#23397f',
    white: '#ffffff',
    whiteLilac: '#f6f8fb',
    black: '#000000',
    cadetBlue: '#a4aac2',
    seaGreen: '#338164',
    hesaRed: '#a93439',
    hesaRedLight: '#ddaeb0',
};

export const sizes = {
    xs: '0.5rem',
    sm: '0.85rem',
    md: '1rem',
    lg: '1.5rem',
    xl: '2rem',
    xxl: '2.5rem',
};
