import { Box, CircularProgress } from '@mui/material';

import styles from './loading.module.scss';

const Loading = () => {
    return (
        <Box className={styles.loadingWrapper}>
            <CircularProgress aria-label="Circular loading animation" />
        </Box>
    );
};

Loading.displayName = 'Loading';

export default Loading;
