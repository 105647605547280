export const COLUMN_NAMES_MAP = {
    collection: [
        'Provider ID',
        'Provider Name',
        'Submission ID',
        'Collection Activity ID',
        'Filename',
        'Time and date',
        'User',
        'Activity',
    ],

    provider: [
        'Submission ID',
        'Collection Activity ID',
        'Filename',
        'Time and date',
        'User',
        'Activity',
    ],
};
