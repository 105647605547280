import { Chip } from '@mui/material';
import {
    CollectionStateKey,
    collectionStates,
} from 'src/constants/collections';
import { capitalizeString } from 'utils/capitalizeString/capitalizeString';
import { formatTestId } from 'utils/formatTestId/formatTestId';

const CollectionStatus = ({
    id,
    reference,
}: {
    id: number;
    reference: string;
}) => {
    const collectionState =
        Object.keys(collectionStates).find(
            key => collectionStates[key as CollectionStateKey] === id,
        ) || 'Unassigned';

    const label = capitalizeString(collectionState.replace(/_/g, ' '));

    return (
        <Chip
            variant="outlined"
            label={label}
            disabled={label === 'Unassigned'}
            data-test-id={formatTestId('collection state', reference)}
        />
    );
};

export default CollectionStatus;
