import React from 'react';
import { InfoOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { ReactComponent as DownArrow } from 'assets/icons/down_arrow.svg';
import { ReactComponent as DrillDownCorner } from 'assets/icons/drill_down_corner.svg';
import { ReactComponent as Exclamation } from 'assets/icons/error.svg';
import { ReactComponent as UpArrow } from 'assets/icons/up_arrow.svg';
import classNames from 'classnames';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import styles from './credibilityCell.module.scss';

export const CredibilityCell = ({
    Colour,
    CellType,
    Label,
    TopLeftLabel,
    TopLeftValue,
    TopLeftSuffix,
    TopRightLabel,
    TopRightValue,
    TopRightSuffix,
    BottomLeftLabel,
    BottomLeftValue,
    BottomLeftSuffix,
    BottomRightLabel,
    BottomRightValue,
    BottomRightSuffix,
    clickHandler,
    CellId,
    isRow,
    isExpanded,
    ShadingRules,
    IconUpArrow,
    IconDownArrow,
    IconExclamation,
    ChangeTooltipText,
    canDrillDown,
}) => {
    const cellBackground = classNames({
        [styles.border]: true,
        [styles.yellowBackground]: Colour === 'Yellow',
        [styles.whiteBackground]: Colour === 'Clear',
        [styles.headerBackground]: Colour === 'HeaderColour',
        [styles.salmonBackground]: Colour === 'Salmon',
    });

    const dynamicCell = classNames({
        [styles.innerCell]: true,
        [styles.hidden]: !isExpanded,
    });

    const leftColumn = classNames({
        [styles.column]: true,
        [styles.leftColumn]: true,
    });

    const rightColumn = classNames({
        [styles.column]: true,
        [styles.rightColumn]: true,
        [styles.hidden]: !isExpanded,
    });

    const header = classNames({
        [styles.rowHeader]: isRow,
        [styles.header]: true,
    });

    const CellInterior = React.forwardRef((props, ref) => (
        <td {...props} className={cellBackground} ref={ref}>
            {ShadingRules && ShadingRules?.RuleText?.length ? (
                <div className={styles.infoWrapper}>
                    <Tooltip
                        id="cell-info-tooltip"
                        leaveDelay={200}
                        arrow
                        describeChild
                        classes={{ popper: 'hdp-override' }}
                        title={
                            <div style={{ whiteSpace: 'pre-line' }}>
                                {ShadingRules.RuleText.join('\n')}
                            </div>
                        }
                    >
                        <InfoOutlined
                            className={styles.info}
                            aria-label="rule information icon"
                            aria-role="img"
                        />
                    </Tooltip>
                </div>
            ) : null}
            <div className={styles.credibilityCell}>
                <div className={leftColumn}>
                    <div className={styles.innerCell}>
                        <span className={styles.label}>{TopLeftLabel}</span>
                        <span className={styles.dataIcons}>
                            {IconDownArrow && ChangeTooltipText && (
                                <Tooltip
                                    id="cell-value-decrease-tooltip"
                                    leaveDelay={200}
                                    arrow
                                    describeChild
                                    classes={{ popper: 'hdp-override' }}
                                    title={ChangeTooltipText}
                                >
                                    <DownArrow
                                        aria-label="value decrease icon"
                                        role="img"
                                    />
                                </Tooltip>
                            )}
                            {IconUpArrow && ChangeTooltipText && (
                                <Tooltip
                                    id="cell-value-increase-tooltip"
                                    leaveDelay={200}
                                    arrow
                                    describeChild
                                    classes={{ popper: 'hdp-override' }}
                                    title={ChangeTooltipText}
                                >
                                    <UpArrow
                                        aria-label="value increase icon"
                                        role="img"
                                    />
                                </Tooltip>
                            )}
                            <span className={styles.dataIconsLabel}>
                                {TopLeftValue}
                                {TopLeftSuffix}
                            </span>
                            {IconExclamation && (
                                <Exclamation
                                    className={styles.noPointer}
                                    aria-label="new failure icon"
                                    role="img"
                                />
                            )}
                        </span>
                    </div>
                    <div className={dynamicCell} aria-hidden={!isExpanded}>
                        <span className={styles.label}>{BottomLeftLabel}</span>
                        <span className={styles.data}>
                            {BottomLeftValue}
                            {BottomLeftSuffix}
                        </span>
                    </div>
                </div>
                <div className={rightColumn} aria-hidden={!isExpanded}>
                    <div className={dynamicCell}>
                        <span className={styles.label}>{TopRightLabel}</span>
                        <span className={styles.data}>
                            {TopRightValue}
                            {TopRightSuffix}
                        </span>
                    </div>
                    <div className={dynamicCell} aria-hidden={!isExpanded}>
                        <span className={styles.label}>{BottomRightLabel}</span>
                        <span className={styles.data}>
                            {BottomRightValue}
                            {BottomRightSuffix}
                        </span>
                    </div>
                </div>
            </div>
            {canDrillDown && parseFloat(TopLeftValue) ? (
                <DrillDownCorner
                    className={styles.drillDownCorner}
                    onClick={() => clickHandler(CellId)}
                    aria-label="open drilldown details"
                    role="button"
                    aria-haspopup="dialog"
                    data-test-id={formatTestId('drill down', CellId)}
                />
            ) : null}
        </td>
    ));

    const getScope = () => {
        if (isRow) {
            return 'row';
        }
        if (Label) {
            return 'col';
        }
        return '';
    };

    if (CellType === 'Label' && Label) {
        if (Label) {
            return (
                <th
                    className={`${cellBackground} ${header}`}
                    scope={getScope()}
                >
                    {Label}
                </th>
            );
        }
        return <td className={cellBackground}></td>;
    }

    return <CellInterior />;
};
