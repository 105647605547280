import { ChangeEvent } from 'react';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import styles from './selector.module.scss';

export type SelectorOption = {
    label: string;
    value: string;
    disabled?: boolean;
};

export type SelectorGroupedOptions = {
    type: string;
    groupLabel: string;
    options: SelectorOption[];
};

interface SelectorProps {
    heading: string;
    options: SelectorOption[] | SelectorGroupedOptions[];
    value: string;
    onChange: (value: string) => void;
    grouped?: boolean;
}

export const Selector = ({
    heading,
    options,
    value,
    onChange,
    grouped = false,
}: SelectorProps) => {
    const id = `selector-${heading}`;

    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        onChange(e.target.value);
    };

    const renderOption = (option: SelectorOption) => (
        <option
            key={option.value}
            value={option.value}
            data-test-id={formatTestId('option', option?.label)}
            disabled={option.disabled}
        >
            {option.label}
        </option>
    );

    const renderOptions = () => {
        if (grouped) {
            const groupedOptions = options as SelectorGroupedOptions[];
            return (
                <>
                    {groupedOptions.map(({ groupLabel, options }) => (
                        <optgroup key={groupLabel} label={groupLabel}>
                            {options.map(renderOption)}
                        </optgroup>
                    ))}
                </>
            );
        }

        const ungroupedOptions = options as SelectorOption[];
        return ungroupedOptions.map(renderOption);
    };

    return (
        <div className={styles.selector}>
            <label htmlFor={id} className={styles.label}>
                {heading}
            </label>
            <select
                id={id}
                value={value}
                onChange={handleChange}
                className={styles.dropdown}
                data-test-id={formatTestId('open menu', heading)}
            >
                {renderOptions()}
            </select>
        </div>
    );
};

Selector.displayName = 'Selector';
