import React from 'react';

import styles from './accordionGroup.module.scss';

interface AccordionGroupInterface {
    children: React.ReactNode;
}

const AccordionGroup = ({ children }: AccordionGroupInterface) => {
    return <div className={styles.root}>{children}</div>;
};

export default AccordionGroup;
