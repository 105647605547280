import { generatePath } from 'react-router-dom';
import { DateTime } from 'luxon';
import { FILE_STATUS, fileStatusByKey } from 'src/constants/FileStatus';

import { OVTPATHS } from '../../../constants/constants';
import * as filesApi from '../../../services/api/files';

export const requestFileDownload = async (
    submissionId,
    instId,
    fileName,
    uploaded,
    enriched = false,
) => {
    const uploadedDate = DateTime.fromISO(uploaded, { zone: 'utc' });
    const fullPath = `${instId}/${uploadedDate.year}/${uploadedDate.month}/${uploadedDate.day}`;
    const key = await filesApi.downloadRequest(
        `${fullPath}/${enriched ? 'Enriched_' : ''}${submissionId}_${fileName}`,
    );

    const link = document.createElement('a');
    link.href = key.data;
    link.setAttribute('download', `${enriched ? 'Enriched_' : ''}${fileName}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
};
export const requestOvtFileDownload = async (
    submissionId,
    instId,
    fileName,
    uploaded,
    enriched = false,
) => {
    const uploadedDate = DateTime.fromISO(uploaded);
    const fullPath = `${instId}/${uploadedDate.year}/${uploadedDate.month}/${uploadedDate.day}`;
    const key = await filesApi.ovtDownloadRequest(
        `${fullPath}/OVT/${
            enriched ? 'Enriched_' : ''
        }${submissionId}_${fileName}`,
    );

    const link = document.createElement('a');
    link.href = key.data;
    link.setAttribute('download', `${enriched ? 'Enriched_' : ''}${fileName}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const requestOvtSchemaErrorsFileDownload = async (
    submissionId,
    instId,
    fileName,
    uploaded,
) => {
    const uploadedDate = DateTime.fromISO(uploaded);
    const fullPath = `${instId}/${uploadedDate.year}/${uploadedDate.month}/${uploadedDate.day}`;
    const csvFileName = `${fileName.substr(0, fileName.lastIndexOf('.'))}.csv`;
    const schemaErrorsFileName = `SchemaErrors_${submissionId}_${csvFileName}`;
    const key = await filesApi.ovtDownloadRequest(
        `${fullPath}/OVT/${schemaErrorsFileName}`,
    );

    const link = document.createElement('a');
    link.href = key.data;
    link.setAttribute('download', `${schemaErrorsFileName}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const requestSignOffFormFileDownload = async hash => {
    const key = await filesApi.signOffFormDownloadRequest(hash);
    const link = document.createElement('a');
    link.href = key.data;
    link.setAttribute('download', `${hash}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const requestAllQualityRuleFailuresDownload = async submissionUuid => {
    const { url } = await filesApi.ovtDownloadAllQualityRuleFailures(
        submissionUuid,
    );
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const requestQualityRuleFailuresDownload = async uri => {
    const link = document.createElement('a');
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const calculatePercentage = (numerator, denominator, precision = 2) =>
    parseFloat(((numerator / denominator) * 100).toFixed(precision));

export function getSteps(collectionId, submissionId) {
    return [
        {
            id: 0,
            label: 'Upload',
            name: 'upload',
            path: generatePath(OVTPATHS.SUBMISSON, {
                collectionId,
                submissionId,
                step: 'upload',
            }),
        },
        {
            id: 1,
            label: 'Processing',
            name: 'processing',
            path: generatePath(OVTPATHS.SUBMISSON, {
                collectionId,
                submissionId,
                step: 'processing',
            }),
        },
        {
            id: 2,
            label: 'Quality assurance',
            name: 'reports',
            path: generatePath(OVTPATHS.REPORTS, {
                collectionId,
                submissionId,
                step: 'reports',
            }),
        },
        {
            id: 3,
            label: 'Approval & sign-off',
            name: 'signoff',
            path: generatePath(OVTPATHS.SUBMISSION, {
                collectionId,
                submissionId,
                step: 'signoff',
            }),
        },
    ];
}

export const calculateEvents = status =>
    Object.keys(FILE_STATUS)
        .filter(
            state =>
                fileStatusByKey(state).order < fileStatusByKey(status).order &&
                fileStatusByKey(state).isStep,
        )
        .concat(status)
        .map(state => ({ status: state }));

export function renderTolerance(toleranceType, toleranceFrom, toleranceTo) {
    switch (toleranceType) {
        case 1:
            return toleranceFrom;
        case 2:
            return `${toleranceFrom}%`;
        case 3:
            return `${toleranceFrom}-${toleranceTo}`;
        default:
            return toleranceFrom;
    }
}

export function renderStatus(status) {
    switch (status) {
        case true:
            return 'Outside';
        case false:
            return 'Inside';
        default:
            return 'Outside';
    }
}

export function conjunctionMap(conjunction) {
    switch (conjunction) {
        case 'A': {
            return 'AND';
        }
        case 'O': {
            return 'OR';
        }
        default:
            return '';
    }
}

export const RiskLevel2 = '02';
export const RiskLevel1 = '01';
export const CountryCodeEngland = 'E';

export const getApprovalStates = (riskCode, countryCode) => {
    const analystApproval = {
        ...FILE_STATUS.HESA_ANALYST_APPROVAL,
        code: 'HESA_ANALYST_APPROVAL',
        name: FILE_STATUS.HESA_ANALYST_APPROVAL.label,
    };
    const hesaApproval = {
        ...FILE_STATUS.HESA_APPROVAL,
        code: 'HESA_APPROVAL',
        name: FILE_STATUS.HESA_APPROVAL.label,
    };
    const scApproval = {
        ...FILE_STATUS.SC_APPROVAL,
        code: 'SC_APPROVAL',
        name: FILE_STATUS.SC_APPROVAL.label,
    };

    // High Risk England
    if (riskCode === RiskLevel2 && countryCode === CountryCodeEngland) {
        return [analystApproval, hesaApproval, scApproval];
    }
    // High Risk - Welsh, Scottish, NI
    if (riskCode === RiskLevel2 && countryCode !== CountryCodeEngland) {
        return [analystApproval, hesaApproval];
    }
    // Standard - English
    if (riskCode === RiskLevel1 && countryCode === CountryCodeEngland) {
        return [hesaApproval, scApproval];
    }
    // Standard - Welsh, Scottish, NI
    if (riskCode === RiskLevel1 && countryCode !== CountryCodeEngland) {
        return [hesaApproval];
    }
};

export const issueStatuses = {
    PENDING: 'Pending',
    CREATED: 'Created',
    FAILED: 'Failed to create',
};

export const getIssueStatusLabel = (issue, localIssueStatus) => {
    if (issue) {
        if (issue.issueClosed) {
            return issue.resolution;
        } else if (issue.resolution === 'Open') {
            return issue.status;
        }
    } else if (localIssueStatus) {
        return localIssueStatus;
    }

    return null;
};
