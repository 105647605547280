import { ReactNode } from 'react';

import { useUserContext } from '../useUserContext';

interface RestrictAccessProps {
    allowPermissions: string[];
    render?: () => ReactNode;
    fallBackComponent?: ReactNode;
    children?: ReactNode;
}

export const RestrictAccess = ({
    allowPermissions,
    render,
    fallBackComponent,
    children,
}: RestrictAccessProps) => {
    const { permissionsBySection } = useUserContext();

    if (
        permissionsBySection &&
        allowPermissions &&
        allowPermissions.some(allowPermission => {
            const [sectionName, permissionName] = allowPermission.split('.');
            if ((permissionsBySection[sectionName] || {})[permissionName])
                return true;
        })
    ) {
        return children;
    }

    if (render) {
        return render();
    }

    if (fallBackComponent) {
        return fallBackComponent;
    }

    return null;
};
