import { useUserContext } from 'components';
import { STREAMS } from 'constants/constants';
import { IdsStream } from 'types/auth';

import { Selector } from '../Selector';

export const getStreamInformationFromIdsStream = (stream: IdsStream) => {
    return STREAMS.find(s => s.id === stream.id);
};

export const StreamSelector = () => {
    const { activeStream, streams, changeStream } = useUserContext();

    if (!streams || !activeStream) {
        return null;
    }

    const options = streams.map(getStreamInformationFromIdsStream).map(s => ({
        value: s!.id,
        label: s!.name,
        disabled: !s?.available,
    }));

    return (
        <Selector
            heading={'Stream:'}
            options={options}
            value={activeStream.id}
            onChange={changeStream}
        />
    );
};

StreamSelector.displayName = 'StreamSelector';
