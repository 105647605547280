import { get, getFilenameFromContentHeader, getRaw } from 'services/api/utils';

export const getReportData = ({ reportCode, submissionId }) =>
    get(`/credibilityapi/credibilityreport/${reportCode}/${submissionId}`);

export const getReports = ({ submissionId, providerId }) =>
    get(`/credibility/${submissionId}/get-reports/${providerId}`);

export const getReportDataXlsx = async ({ reportCode, submissionId }) => {
    const res = await getRaw(
        `/credibilityapi/credibilityreport/${reportCode}/${submissionId}/xlsx`,
        {
            headers: {
                Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
        },
    );

    const header = res.headers.get('Content-Disposition');
    const fileName = getFilenameFromContentHeader(header);
    const contents = URL.createObjectURL(await res.blob());
    return { contents, fileName };
};

export const getChapterReportDataZip = async ({ chapterId, submissionId }) => {
    const res = await getRaw(
        `/credibilityapi/credibilityreport/${submissionId}/zip?chapterId=${chapterId}`,
        {
            headers: {
                Accept: 'application/zip',
            },
        },
    );

    const header = res.headers.get('Content-Disposition');
    const fileName = getFilenameFromContentHeader(header);
    const contents = URL.createObjectURL(await res.blob());
    return { contents, fileName };
};

export const getAllReportDataZip = async ({ submissionId }) => {
    const res = await getRaw(
        `/credibilityapi/credibilityreport/${submissionId}/zip`,
        {
            headers: {
                Accept: 'application/zip',
            },
        },
    );

    const header = res.headers.get('Content-Disposition');
    const fileName = getFilenameFromContentHeader(header);
    const contents = URL.createObjectURL(await res.blob());
    return { contents, fileName };
};

export const getPreviousSubmissions = ({
    submissionId,
    collectionId,
    instId,
}) =>
    get(
        `/credibility/${submissionId}/get-previous-submissions/${collectionId}/${instId}`,
    );

export const getCompareData = ({
    reportCode,
    currentSubmissionId,
    previousSubmissionId,
}) =>
    get(
        `/credibilityapi/get-compare-data/${reportCode}/${currentSubmissionId}/${previousSubmissionId}`,
    );

export const getDrillDownData = ({
    reportCode,
    submissionId,
    cellID,
    limit,
    offset,
    sortField,
    sortOrder,
}) => {
    return get(
        `/credibilityapi/credibilityreport/${reportCode}/${submissionId}/drilldown/${cellID}`,
        {
            query: {
                limit,
                sortField,
                sortOrder,
                ...(offset && { offset: offset * limit }),
            },
        },
    );
};

export const getDrillDownDataCsv = ({ reportCode, submissionId, cellID }) => {
    return getRaw(
        `/credibilityapi/credibilityreport/${reportCode}/${submissionId}/drilldown/${cellID}/csv`,
        { headers: { Accept: 'text/csv' } },
    ).then(async res => {
        const header = res.headers.get('Content-Disposition');
        const fileName = getFilenameFromContentHeader(header);
        const text = await res.text();
        return { text, fileName };
    });
};
