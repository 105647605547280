import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { submissions as submissionsApi } from 'services/api';
import {
    createNilReturn,
    requestResubmission,
    signoff,
    updateSubmissionState,
} from 'src/services/api/submissions';

export const uiStates = {
    UNINITIALISED: 1,
    LOADING: 2,
    LOADED: 3,
    LOADING_FAILED: 4,
};

export const getUiState = dataSets => {
    const every = status => dataSets.every(d => d.status === status);
    const some = status => dataSets.some(d => d.status === status);

    if (some('loading')) {
        return uiStates.LOADING;
    }

    if (every('success')) {
        return uiStates.LOADED;
    }

    if (some('error')) {
        return uiStates.LOADING_FAILED;
    }

    return uiStates.UNINITIALISED;
};

const fetchProviderStatuses = async query =>
    submissionsApi.getProviderStatuses(query).then(res => res.providerStatuses);

const fetchOvtProviderStatuses = async query =>
    query.isHesaAdmin
        ? submissionsApi
              .getOvtProviderStatuses(query)
              .then(res => res.providerStatuses)
        : [];

const fetchStates = async () =>
    submissionsApi.getStates().then(res => res.states);

export const useProviderStatuses = collectionReference =>
    useQuery({
        queryKey: ['providerStatuses', collectionReference],
        queryFn: () => fetchProviderStatuses({ collectionReference }),
        refetchInterval: false,
        refetchOnMount: 'always',
    });

export const useOvtProviderStatuses = (collectionReference, isHesaAdmin) =>
    useQuery({
        queryKey: ['ovtProviderStatuses', collectionReference],
        queryFn: () =>
            fetchOvtProviderStatuses({ isHesaAdmin, collectionReference }),
        refetchInterval: false,
        refetchOnMount: 'always',
    });

export const useSubmissionStates = () =>
    useQuery({
        queryKey: ['submissionStates'],
        queryFn: fetchStates,
        refetchInterval: false,
    });

export const useApproveOrDeclineSubmission = ({
    latestSubmission,
    onSuccess,
    provider,
    nextState,
}) =>
    useMutation({
        mutationKey: [provider?.institutionId, 'approveOrDecline'],
        mutationFn: () =>
            updateSubmissionState({
                submissionId: latestSubmission?.uuid,
                statusId: nextState?.id,
                action: 'approve',
            }),
        onSuccess,
    });

const useCreateNilReturn = ({ provider, collection, onSuccess }) =>
    useMutation({
        mutationKey: [provider?.institutionId, 'createNilReturn'],
        mutationFn: () =>
            createNilReturn({
                instId: provider?.institutionId,
                collectionId: collection?.id,
            }),
        onSuccess,
    });

const useSignOffSubmission = ({ latestSubmission, onSuccess }) =>
    useMutation({
        mutationKey: [latestSubmission?.uuid, 'signOff'],
        mutationFn: () => signoff({ submissionId: latestSubmission?.id }),
        onSuccess,
    });

const useRequestResubmission = ({ latestSubmission, onSuccess }) =>
    useMutation({
        mutationKey: [latestSubmission?.uuid, 'requestResubmission'],
        mutationFn: () => requestResubmission({ uuid: latestSubmission?.uuid }),
        onSuccess,
    });

export const useSubmissionSummaryActions = ({
    latestSubmission,
    provider,
    collection,
    nextState,
    onSuccess: onSuccessProp,
}) => {
    const queryClient = useQueryClient();

    const onSuccess = useCallback(() => {
        queryClient
            .refetchQueries({
                queryKey: ['providerStatuses', collection?.reference],
                type: 'active',
            })
            .then(() => {
                if (typeof onSuccessProp === 'function') {
                    onSuccessProp();
                }
            });
    }, [collection?.reference, onSuccessProp, queryClient]);

    const approve = useApproveOrDeclineSubmission({
        latestSubmission,
        provider,
        onSuccess,
        nextState,
    });

    const createNilReturn = useCreateNilReturn({
        provider,
        collection,
        onSuccess,
    });

    const signOff = useSignOffSubmission({ latestSubmission, onSuccess });

    const requestResubmission = useRequestResubmission({
        latestSubmission,
        onSuccess,
    });

    return { approve, createNilReturn, signOff, requestResubmission };
};
