import React from 'react';
import { Check } from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';
import PropTypes from 'prop-types';

export const SaveButton = ({
    onClick,
    loading = false,
    disabled = false,
    ...props
}) => {
    return (
        <IconButton
            onClick={onClick}
            disabled={loading || disabled}
            color={'primary'}
            size="large"
            {...props}
        >
            {loading ? (
                <CircularProgress
                    size={16}
                    aria-label="Circular loading animation"
                />
            ) : (
                <Check size={'small'} />
            )}
        </IconButton>
    );
};

SaveButton.propTypes = {
    title: PropTypes.string,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};
