import { useQuery } from '@tanstack/react-query';

import { getUseProviderCollectionsQueryOptions } from './options';

export default function useProviderCollections({
    instId,
    state = [],
}: {
    instId?: string;
    state: string[];
}) {
    const options = getUseProviderCollectionsQueryOptions(instId, state);
    return useQuery(options);
}
