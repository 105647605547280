export const approvalConfig = [
    {
        name: 'Hesa analyst approval',
        code: 'HESA_ANALYST_APPROVAL',
        displayStates: {
            error: {
                label: '',
                states: [],
            },
            passed: {
                label: 'HESA Analyst approval',
                states: ['HESA_APPROVAL', 'APPROVED'],
            },
            waiting: {
                label: 'Awaiting HESA Analyst approval',
                states: ['HESA_ANALYST_APPROVAL'],
            },
        },
    },
    {
        name: 'Hesa approval',
        code: 'HESA_APPROVAL',
        displayStates: {
            error: {
                label: '',
                states: [],
            },
            passed: {
                label: 'HESA approval',
                states: ['SC_APPROVAL', 'APPROVED'],
            },
            waiting: {
                label: 'Awaiting HESA approval',
                states: ['HESA_APPROVAL'],
            },
        },
    },
    {
        name: 'SC approval',
        code: 'SC_APPROVAL',
        displayStates: {
            error: {
                label: '',
                states: [],
            },
            passed: {
                label: 'SC approval',
                states: ['APPROVED'],
            },
            waiting: {
                label: 'Awaiting SC approval',
                states: ['SC_APPROVAL'],
            },
        },
    },
];
