import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    components: {
        MuiFormControl: {
            root: {
                minWidth: 185,
                marginBottom: 5,
                marginRight: 5,
            },
        },
        MuiButton: {
            root: {
                minWidth: 100,
            },
        },
        MuiIconButton: {
            root: {
                padding: 5,
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: 40,
            },
        },
        MuiNativeSelect: {
            root: {
                fontSize: '14px',
            },
        },
        MuiListItemText: {
            primary: {
                overflowWrap: 'break-word',
            },
        },
        MuiAccordion: {
            root: {
                border: '1px solid rgba(0, 0, 0, #ecf4fb)',
                boxShadow: 'none',
            },
        },
    },
});

export default theme;
