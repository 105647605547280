import { queryOptions } from '@tanstack/react-query';
import { regulators } from 'services/api';

export const getUseRegulatorsQueryOptions = () => {
    return queryOptions({
        queryKey: ['regulators', 'list'],
        queryFn: () => regulators.getAll(),
    });
};

export const getUseRegulatorQueryOptions = (code: string) => {
    return queryOptions({
        queryKey: ['regulators', code],
        queryFn: () => regulators.get({ code }),
    });
};
