import { HTMLAttributes } from 'react';
import {
    AutocompleteGroupedOption,
    useAutocomplete,
    UseAutocompleteRenderedOption,
} from '@mui/material';

import { AutocompleteOption } from '../AutocompleteDropdown';
import AutocompleteDropdownItem from '../AutocompleteDropdownItem/AutocompleteDropdownItem';

import styles from './optionsList.module.scss';

interface OptionsListProps {
    groupedOptions:
        | (string | number)[]
        | AutocompleteGroupedOption<string | number>[];
    options: AutocompleteOption[];
    id: string;
    isAllSelected: boolean;
    getOptionProps: (
        renderedOption: UseAutocompleteRenderedOption<string>,
    ) => HTMLAttributes<HTMLLIElement> & {
        key: string | number;
    };
    value: (string | number)[];
    getListboxProps: ReturnType<typeof useAutocomplete>['getListboxProps'];
}

const OptionsList = ({
    groupedOptions,
    options,
    id,
    isAllSelected,
    getOptionProps,
    value,
    getListboxProps,
}: OptionsListProps) => (
    <ul {...getListboxProps()} className={styles.listBox}>
        {groupedOptions.map((option, index) => {
            const optionData = options.find(opt => opt.value === option);

            if (!optionData) {
                return null;
            }
            return (
                <AutocompleteDropdownItem
                    isAllSelected={isAllSelected}
                    key={`${id}-option-${index}`}
                    option={optionData}
                    index={index}
                    getOptionProps={getOptionProps}
                    value={value}
                />
            );
        })}
    </ul>
);

export default OptionsList;
