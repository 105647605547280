export const collectionStates = {
    DRAFT: 1,
    DELETED: 2,
    OPEN: 3,
    CLOSED: 4,
    HISTORIC_AMENDMENT: 5,
    ARCHIVED: 6,
    VALIDATION: 7,
};

export type CollectionStateKey = keyof typeof collectionStates;
