import React, { useEffect, useMemo, useRef } from 'react';
import { generatePath, Link, useOutletContext } from 'react-router-dom';
import {
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
} from '@mui/material';
import { RestrictAccess } from 'components';
import { useUserContext } from 'components';
import AccessDenied from 'src/components/AccessDenied/AccessDenied';
import { PATHS } from 'src/constants/constants';
import {
    fileStatusById,
    fileStatusByKey,
    ifStatusIdIsUpToQualityProcessingComplete,
    STATUS_ICONS,
} from 'src/constants/FileStatus';
import { getApprovalStates } from 'src/pages/Collections/Submission/utils';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';
import { v4 as uuid } from 'uuid';

import { useStepper } from '../Stepper/StepperContext';

import { approvalConfig } from './approval.config';

import styles from './approval.module.scss';

const getContinueButtonLabel = (reSubRequired, isHesa) => {
    if (reSubRequired) {
        return isHesa ? 'Return to monitoring' : 'Return to collections';
    }
    return 'Continue';
};

const Approval = () => {
    const { isHesa } = useUserContext();
    const { data, submissionId, collectionId, setPageTitle } =
        useOutletContext();
    const submissionApprovalStates = useRef(
        getApprovalStates(data.provider.riskCode, data.provider.countryCode),
    );
    const { navigateToStep, isSubmissionSignedOff } = useStepper();

    const displayStatesConstants = {
        ERROR: 'error',
        WAITING: 'waiting',
        PASSED: 'passed',
    };
    const displayStateIcons = {
        [displayStatesConstants.WAITING]: STATUS_ICONS.STATE_IN_PROGRESS,
        [displayStatesConstants.ERROR]: STATUS_ICONS.STATE_FAILED,
        [displayStatesConstants.PASSED]: STATUS_ICONS.STATE_CAN_PROGRESS,
    };

    useEffect(() => {
        setPageTitle('Approval');
    }, [setPageTitle]);

    const checkIfErrorInStates = (states, submissionStateId) =>
        states.some(state => fileStatusByKey(state).id === submissionStateId);

    const checkIfWaitingInStates = (states, submissionStateId) =>
        states.some(
            state =>
                fileStatusByKey(state).order >
                    fileStatusById(submissionStateId).order ||
                fileStatusByKey(state).order ===
                    fileStatusById(submissionStateId).order,
        );

    const checkIfPassedInStates = (states, submissionStateId) =>
        states.some(
            state =>
                fileStatusByKey(state).order <=
                fileStatusById(submissionStateId).order,
        );

    const getStateForStep = (displayStates, submissionStateId) => {
        if (
            checkIfErrorInStates(
                displayStates[displayStatesConstants.ERROR].states,
                submissionStateId,
            )
        ) {
            return {
                displayState: displayStatesConstants.ERROR,
                label: displayStates[displayStatesConstants.ERROR].label,
            };
        }

        if (
            checkIfPassedInStates(
                displayStates[displayStatesConstants.PASSED].states,
                submissionStateId,
            )
        ) {
            return {
                displayState: displayStatesConstants.PASSED,
                label: displayStates[displayStatesConstants.PASSED].label,
            };
        }

        if (
            checkIfWaitingInStates(
                displayStates[displayStatesConstants.WAITING].states,
                submissionStateId,
            )
        ) {
            return {
                displayState: displayStatesConstants.WAITING,
                label: displayStates[displayStatesConstants.WAITING].label,
            };
        }
    };

    const reSubRequired = useMemo(
        () =>
            data?.status.code === 'RESUBMISSION_REQUIRED' ||
            data?.status.code === 'RESUBMISSION_REQUIRED_HISTORIC_AMENDMENT',
        [data],
    );

    const canResubmit = useMemo(
        () =>
            reSubRequired ||
            ifStatusIdIsUpToQualityProcessingComplete(data?.status.id),
        [data?.status.id, reSubRequired],
    );

    const inApproval = () =>
        ['HESA_ANALYST_APPROVAL', 'HESA_APPROVAL', 'SC_APPROVAL'].includes(
            data?.status.code,
        );

    const isApproved = useMemo(() => {
        if (reSubRequired) return false;

        return (
            fileStatusById(data?.status.id).order >=
            fileStatusByKey('APPROVED').order
        );
    }, [data?.status.id, reSubRequired]);

    const uploadNewSubmissionLink = generatePath(PATHS.UPLOAD, {
        collectionId,
        submissionId: uuid().toUpperCase(),
    });

    const canContinue = reSubRequired || isApproved || isSubmissionSignedOff;

    const stateAllowed = step => {
        return submissionApprovalStates.current.find(
            state => state.code === step.code,
        );
    };

    const renderProcessingSteps = status => {
        return approvalConfig.filter(stateAllowed).map(step => {
            const { displayState, label } = getStateForStep(
                step.displayStates,
                status.id,
            );
            return (
                <ListItem key={crypto.randomUUID()} className={styles.state}>
                    <ListItemIcon className={styles.icon}>
                        {displayStateIcons[displayState]}
                    </ListItemIcon>
                    <ListItemText primary={label} />
                </ListItem>
            );
        });
    };

    return (
        <RestrictAccess
            allowPermissions={['collections.approval-and-sign-off']}
            render={() => <AccessDenied mt={8} />}
        >
            {data.status ? (
                <div>
                    <Paper className={styles.wrapper}>
                        <h2 className={styles.heading}>Approval</h2>

                        {isApproved ? (
                            <p className="margin--bottom-1">
                                Submission with ID{' '}
                                <strong>{submissionId}</strong> has been
                                approved
                            </p>
                        ) : (
                            ''
                        )}

                        {inApproval() ? (
                            <React.Fragment>
                                <p className="margin--bottom-1">
                                    Your submission is going through the
                                    approval process that is relevant to your
                                    provider.
                                </p>

                                <p className="margin--bottom-1">
                                    When approval is completed you will need to
                                    return here to request sign-off from your
                                    provider&apos;s Accountable Office (England
                                    and Wales) or Head of Provider (Scotland and
                                    Northern Ireland) to complete the collection
                                    process.
                                </p>
                            </React.Fragment>
                        ) : (
                            ''
                        )}

                        {!reSubRequired ? (
                            <List className={styles.states}>
                                <ListItem className={styles.state}>
                                    <ListItemText
                                        primary="Required approval states:"
                                        className={styles.bold}
                                    />
                                </ListItem>
                                {renderProcessingSteps(data.status)}
                            </List>
                        ) : (
                            <React.Fragment>
                                <h3
                                    className={`${styles.heading} ${styles.red} margin--top-0-5`}
                                >
                                    Resubmission required
                                </h3>
                                <p className="margin--top-1">
                                    <strong>
                                        Your submission has not been approved
                                        and a resubmission is now required. This
                                        may be because
                                    </strong>
                                </p>
                                <p>
                                    - Your submission has not met the quality
                                    standards expected.
                                </p>
                                <p>
                                    - You may have requested to make a
                                    resubmission.
                                </p>
                                <p>
                                    - Historical amendment changes do not align
                                    with the expected changes.
                                </p>
                                <p>
                                    - There are outstanding queries with your
                                    data that need attention.
                                </p>
                                <p className="margin--top-1">
                                    <strong>Next steps:</strong>
                                </p>
                                <p>
                                    You are required to make a new submission to
                                    this collection.
                                </p>
                            </React.Fragment>
                        )}
                    </Paper>
                    <div className={styles.buttons}>
                        {/* TODO: Replace me with ContinueBackUploadNew component */}
                        <Button
                            className="hdp-override large"
                            data-test="Upload"
                            disabled={!canContinue}
                            disableRipple={true}
                            variant="contained"
                            onClick={() =>
                                reSubRequired
                                    ? navigateToStep('Submissions')
                                    : navigateToStep('Sign-off')
                            }
                            data-test-id={
                                !reSubRequired
                                    ? formatTestId('continue')
                                    : formatTestId('return to dashboard')
                            }
                        >
                            {getContinueButtonLabel(reSubRequired, isHesa)}
                        </Button>
                        {!reSubRequired ? (
                            <Button
                                className="hdp-override large hdp-override--grey"
                                data-test="Back"
                                disabled={false}
                                disableRipple={true}
                                variant="contained"
                                onClick={() => navigateToStep('Submit')}
                                data-test-id="back"
                            >
                                Back
                            </Button>
                        ) : (
                            ''
                        )}

                        {reSubRequired ? (
                            <RestrictAccess
                                allowPermissions={[
                                    'collections.upload-submission-file',
                                ]}
                            >
                                <Button
                                    className={`hdp-override large hdp-override--grey ${styles.uploadButton}`}
                                    data-test="Upload new file"
                                    disabled={!canResubmit}
                                    disableRipple={true}
                                    variant="contained"
                                    data-test-id={formatTestId(
                                        'upload new file',
                                    )}
                                    component={Link}
                                    to={uploadNewSubmissionLink}
                                >
                                    Upload new file
                                </Button>
                            </RestrictAccess>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="margin--top-1">
                        {reSubRequired ? (
                            <span>
                                If you want to discuss this please contact{' '}
                                <a href="mailto:liaison@hesa.ac.uk">
                                    HESA Liaison.
                                </a>
                            </span>
                        ) : (
                            <span>
                                Please contact{' '}
                                <a href="mailto:liaison@hesa.ac.uk">
                                    HESA Liaison
                                </a>{' '}
                                if a resubmission is required.
                            </span>
                        )}
                    </div>
                </div>
            ) : (
                ''
            )}
        </RestrictAccess>
    );
};

export default Approval;
