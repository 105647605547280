import { KeyboardArrowDown } from '@mui/icons-material';
import { useAutocomplete } from '@mui/material';
import classNames from 'classnames';

import ClearButton from '../ClearButton/ClearButton';

import styles from './customInput.module.scss';

interface CustomInputProps {
    getInputProps: ReturnType<typeof useAutocomplete>['getInputProps'];
    inputValue: string;
    focused: boolean;
    expanded: boolean;
    setAnchorEl: (element: HTMLDivElement | null) => void;
    setIsOpen: (open: boolean) => void;
    isOpen: boolean;
    value: (number | string)[];
    placeholder: string;
    handleClearClick: () => void;
}

const CustomInput = ({
    getInputProps,
    inputValue,
    focused,
    expanded,
    setAnchorEl,
    setIsOpen,
    isOpen,
    value,
    placeholder,
    handleClearClick,
}: CustomInputProps) => {
    return (
        <div
            ref={setAnchorEl}
            className={classNames(styles.inputWrapper, {
                [styles.inputFocused]: focused,
            })}
        >
            <input
                {...getInputProps()}
                value={inputValue}
                placeholder={placeholder}
            />
            {value.length > 0 && (
                <ClearButton
                    handleClearClick={handleClearClick}
                    value={value}
                />
            )}
            <KeyboardArrowDown
                className={classNames(styles.icon, {
                    [styles.iconExpanded]: expanded,
                })}
                onClick={() => setIsOpen(!isOpen)}
            />
        </div>
    );
};

export default CustomInput;
