import React from 'react';
import { Edit } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

import styles from './editButton.module.scss';

export const EditButton = ({ title, onClick, ...props }) => {
    return (
        <Tooltip title={title} arrow placement={'top'} disableRipple={true}>
            <IconButton
                className={styles.editButton}
                onClick={onClick}
                size="large"
                {...props}
            >
                <Edit size={'small'} />
            </IconButton>
        </Tooltip>
    );
};

EditButton.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};
