import { Box, Button, Paper, Typography } from '@mui/material';

interface LoadingFailedProps {
    onTryAgain: () => void;
    message: string;
}

const LoadingFailed = ({ onTryAgain, message }: LoadingFailedProps) => (
    <Paper>
        <Box
            p={4}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
        >
            <Typography variant={'body2'} paragraph>
                {message}
            </Typography>
            <Button variant={'outlined'} size={'small'} onClick={onTryAgain}>
                Try again
            </Button>
        </Box>
    </Paper>
);

export default LoadingFailed;
