import { queryOptions } from '@tanstack/react-query';

import { getUser } from './lib';

export const getUseAuthUserQueryOptions = () => {
    return queryOptions({
        queryKey: ['auth', 'user'],
        queryFn: () => getUser(),
    });
};
