import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { InfoOutlined } from '@mui/icons-material';
import {
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    Paper,
    Switch,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Tabs,
    Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ReactComponent as DownArrow } from 'assets/icons/down_arrow.svg';
import { ReactComponent as DrillDownCorner } from 'assets/icons/drill_down_corner.svg';
import { ReactComponent as Exclamation } from 'assets/icons/error.svg';
import { ReactComponent as SalmonSquare } from 'assets/icons/salmon_square.svg';
import { ReactComponent as UpArrow } from 'assets/icons/up_arrow.svg';
import { ReactComponent as YellowSquare } from 'assets/icons/yellow_square.svg';
import { useUserContext } from 'components';
import TabPanel from 'components/Tabs/TabPanel';
import { DateTime } from 'luxon';
import { credibility as credibilityApi } from 'services/api';
import { ColumnSelectList } from 'src/components/ColumnSelectList/ColumnSelectList';
import ConfirmationPopup from 'src/components/ConfirmationPopup/ConfirmationPopup';
import InformationPopup from 'src/components/InformationPopup/InformationPopup';
import { TABLE_PARAMS_IDS } from 'src/constants/constants';
import useTableParams from 'src/hooks/useTableParams/useTableParams';
import { CredibilityCell } from 'src/pages/Collections/Submission/Steps/Reports/Credibility/CredibilityCell/CredibilityCell';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import styles from './credibilityReport.module.scss';

const getCellDownloadQuery = async (reportCode, submissionId, cellID) => {
    const data = await credibilityApi.getDrillDownDataCsv({
        reportCode,
        submissionId,
        cellID,
    });

    return {
        href: `data:text/csv;charset=utf-8,${encodeURIComponent(data.text)}`,
        download: data.fileName,
    };
};
const getReportDownloadQuery = async (reportCode, submissionId) => {
    const data = await credibilityApi.getReportDataXlsx({
        reportCode,
        submissionId,
    });

    return {
        href: data.contents,
        download: data.fileName,
    };
};

const CredibilityReport = () => {
    const { isProvider, isHesa, roles } = useUserContext();
    const { reportCode, submissionId, collectionId, instId } = useParams();
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogDataReady, setDialogDataReady] = React.useState(false);
    const [dialogErrorOpen, setDialogErrorOpen] = React.useState(false);
    const [dialogErrorMsg, setDialogErrorMsg] = React.useState(
        'Sorry about this, seems like something un-explained has happened',
    );
    const [pagingData, setPagingData] = useState([]);
    const [credibilityReport, setCredibilityReport] = useState();
    const [previousSubmissions, setPreviousSubmissions] = useState();
    const [drillDownData, setDrillDownData] = useState();

    const [loadingFailed, setLoadingFailed] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const navigate = useNavigate();
    const [isFetchingReport, setIsFetchingReport] = useState(false);

    const DEFAULT_TABLE_PARAMETERS = {
        offset: 0,
        limit: 10,
        selectedPreviousSubmission: {},
        activeTab: 0,
        activeCell: '',
        sortBy: '',
        sortOrder: 'desc',
        toggleDetails: false,
        compare: false,
        isMoreData: false,
    };

    const tableId = TABLE_PARAMS_IDS.ONLINE_VALIDATION_QUALITYRULE;

    const { values: tableParams, methods: tableParamsMethods } = useTableParams(
        tableId,
        DEFAULT_TABLE_PARAMETERS,
    );

    const {
        limit,
        offset,
        selectedPreviousSubmission,
        activeTab,
        activeCell,
        sortBy,
        sortOrder,
        toggleDetails,
        compare,
        isMoreData,
    } = tableParams;

    const {
        setLimit,
        setOffset,
        setSelectedPreviousSubmission,
        setActiveTab,
        setActiveCell,
        setSortBy,
        setSortOrder,
        setToggleDetails,
        setCompare,
        setIsMoreData,
    } = tableParamsMethods;

    const [isFetchingDrillDownData, setIsFetchingDrillDownData] =
        useState(false);

    const { data: cellDownloadData } = useQuery({
        queryKey: ['cell-download', activeCell, submissionId],
        queryFn: () =>
            getCellDownloadQuery(reportCode, submissionId, activeCell),
        enabled: Boolean(drillDownData) && Boolean(activeCell),
    });

    const { data: reportDownloadData } = useQuery({
        queryKey: ['report-download', reportCode, submissionId],
        queryFn: () => getReportDownloadQuery(reportCode, submissionId),
        enabled:
            Boolean(reportCode) &&
            Boolean(submissionId) &&
            Boolean(credibilityReport),
    });

    const toggleDetailsOpen = () => {
        setToggleDetails(!toggleDetails);
    };

    const handleSortByClicked = sortByColumn => {
        let newSortDirection = sortOrder;
        if (sortBy === sortByColumn) {
            if (sortOrder === 'asc') {
                newSortDirection = 'desc';
            } else {
                newSortDirection = 'asc';
            }
        }

        setSortOrder(newSortDirection);
        setSortBy(sortByColumn);
        setOffset(0);
        getDrillDownData(
            submissionId,
            reportCode,
            activeCell,
            limit,
            0,
            sortByColumn,
            newSortDirection,
        );
    };

    const hasDrillDownPerms = () =>
        (isProvider && !roles.includes('guest')) || isHesa;

    const pageHandler = (offset, limit) => {
        setDialogDataReady(false);
        setOffset(offset);
        setLimit(limit);
        getDrillDownData(
            submissionId,
            reportCode,
            activeCell,
            limit,
            offset,
            sortBy,
            sortOrder,
        );
    };
    const handleChangeRowsPerPage = event => pageHandler(0, event.target.value);
    const handleChangePage = (_, page) => pageHandler(page, limit);

    const submissionSelected = async selectedSubmission => {
        if (selectedSubmission && selectedSubmission.fullSubmissionId) {
            setTableLoading(true);
            setSelectedPreviousSubmission(selectedSubmission);
            await getCompareData(
                reportCode,
                submissionId,
                selectedSubmission.fullSubmissionId,
            );
        }
    };

    const getReportData = useCallback(async (reportCode, submissionId) => {
        setIsFetchingReport(true);
        try {
            const credibilityReport = await credibilityApi.getReportData({
                reportCode,
                submissionId,
            });
            setCredibilityReport(credibilityReport);
            setIsMoreData(credibilityReport?.ReportMetadata?.StartExpanded);
        } catch (e) {
            setLoadingFailed(true);
        }
        setIsFetchingReport(false);
    }, []);

    const getCompareData = useCallback(
        async (reportCode, currentSubmissionId, previousSubmissionId) => {
            try {
                const compareData = await credibilityApi.getCompareData({
                    reportCode,
                    currentSubmissionId,
                    previousSubmissionId,
                });
                setCredibilityReport(compareData);
                setIsMoreData(compareData?.ReportMetadata?.StartExpanded);
                setTableLoading(false);
                if (compareData.Error) {
                    setDialogErrorMsg(compareData.ErrorMsg);
                    setDialogErrorOpen(true);
                }
            } catch (e) {
                setLoadingFailed(true);
                setDialogErrorOpen(true);
            }
        },
        [],
    );

    const getPreviousSubmissions = useCallback(
        async (submissionId, collectionId, instId) => {
            try {
                const previousSubmissions =
                    await credibilityApi.getPreviousSubmissions({
                        submissionId,
                        collectionId,
                        instId,
                    });
                setPreviousSubmissions(
                    previousSubmissions.map(submision =>
                        previousSubmissionMapper(submision),
                    ),
                );
            } catch (e) {
                setLoadingFailed(true);
            }
        },
        [],
    );

    const getDrillDownData = useCallback(
        async (
            submissionId,
            reportCode,
            cellID,
            limit,
            offset,
            sortField = '',
            sortOrder = '',
        ) => {
            setIsFetchingDrillDownData(true);
            try {
                const drillDownData = await credibilityApi.getDrillDownData({
                    submissionId,
                    reportCode,
                    cellID,
                    limit,
                    offset,
                    sortField,
                    sortOrder,
                });
                setDrillDownData(drillDownData);
                setPagingData(drillDownData?.PagingMetadata);
                setDialogDataReady(true);
            } catch (e) {
                setLoadingFailed(true);
            } finally {
                setIsFetchingDrillDownData(false);
            }
        },
        [],
    );

    const previousSubmissionMapper = submission => {
        return submission
            ? {
                  fullSubmissionId: submission.submissionId,
                  submissionId: submission.submissionId.substring(
                      submission.submissionId.length - 9,
                  ),
                  uploadDate: DateTime.fromISO(
                      submission.uploadedDate,
                  ).toFormat('dd/MM/yyyy HH:mm'),
              }
            : submission;
    };

    const changeTab = (event, newValue) => {
        setActiveTab(newValue);
    };

    const closeDialog = () => {
        setOffset(0);
        setLimit(10);
        setSortBy('');
        setSortOrder('desc');
        setDialogDataReady(false);
        setDialogOpen(false);
    };

    const closeErrorDialog = () => {
        setDialogErrorOpen(false);
    };

    const openDialog = id => {
        setActiveCell(id);
        getDrillDownData(submissionId, reportCode, id, limit, offset);
        setDialogOpen(true);
    };

    const toggleMoreData = event => {
        setIsMoreData(event.target.checked);
    };

    const toggleCompare = async event => {
        setCompare(event.target.checked);
        if (!event.target.checked) {
            setSelectedPreviousSubmission({});
            await getReportData(reportCode, submissionId);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await getReportData(reportCode, submissionId);
            await getPreviousSubmissions(submissionId, collectionId, instId);
        };
        fetchData();
    }, [
        reportCode,
        submissionId,
        getReportData,
        collectionId,
        getPreviousSubmissions,
        instId,
    ]);

    const SymbolsKey = () => {
        return (
            <div>
                <p>Symbols:</p>
                <ul className={styles.symbolList}>
                    <li>
                        <span>
                            <Exclamation
                                aria-label="new failure icon"
                                role="img"
                            />
                        </span>
                        <span>
                            Current submission fails a shading rule where the
                            selected submission did not.
                        </span>
                    </li>
                    <li>
                        <span className={styles.doubleIcon}>
                            <UpArrow
                                aria-label="value increase icon"
                                role="img"
                            />
                            <DownArrow
                                aria-label="value decrease icon"
                                role="img"
                            />
                        </span>
                        <span>
                            Value has increased/decreased between submissions -
                            view more details about how much the
                            increase/decrease is.
                        </span>
                    </li>
                    <li>
                        <span>
                            <InfoOutlined
                                aria-label="rule information icon"
                                aria-hidden="false"
                                role="img"
                            />
                        </span>
                        <span>
                            View more information about why this cell is shaded.
                        </span>
                    </li>
                    <li>
                        <span>
                            <DrillDownCorner
                                aria-label="drill down available icon"
                                role="img"
                            />
                        </span>
                        <span>
                            See the detailed data behind this cell total.
                        </span>
                    </li>
                    <li>
                        <span>
                            <YellowSquare />
                        </span>
                        <span>
                            Data has failed a credibility rule in the current
                            submission and failed that same rule in the compared
                            submission.
                        </span>
                    </li>
                    <li>
                        <span>
                            <SalmonSquare />
                        </span>
                        <span>
                            Data has failed a credibility rule in the current
                            submission, but did not fail that rule in the
                            compared submission.
                        </span>
                    </li>
                </ul>
            </div>
        );
    };

    const ResultsButton = props => {
        const { className } = props;

        return (
            <Button
                className={className}
                data-test-id={formatTestId('download results')}
                disableRipple
                variant="contained"
                component="a"
                disabled={
                    !reportDownloadData?.href || !reportDownloadData?.download
                }
                {...reportDownloadData}
            >
                Download Results
            </Button>
        );
    };

    const goBack = useCallback(() => {
        navigate('../', { relative: true });
    }, [navigate]);

    const DrillDownTable = () => {
        if (!drillDownData?.DrillDown) return null;

        return (
            <>
                <TableContainer component={Paper}>
                    <Table
                        className={styles.table}
                        aria-label="credibility drilldown table"
                        data-test-id={formatTestId(
                            'table',
                            'credibility drill down',
                        )}
                    >
                        <TableHead>
                            <TableRow>
                                {drillDownData?.DrillDown?.Headers.map(col => (
                                    <TableCell
                                        onClick={() => handleSortByClicked(col)}
                                        key={crypto.randomUUID()}
                                        className={styles.drilldownSort}
                                        style={{ minWidth: 100 }}
                                    >
                                        <span className={styles.drilldownLabel}>
                                            <strong>{col}</strong>
                                            <TableSortLabel
                                                active={sortBy === col}
                                                direction={sortOrder}
                                            />
                                        </span>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {drillDownData.DrillDown.Data.map((row, index) => (
                                <TableRow
                                    key={crypto.randomUUID()}
                                    className={
                                        index % 2 !== 0 ? styles.blueRow : null
                                    }
                                >
                                    {row.map(cell => (
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            key={crypto.randomUUID()}
                                        >
                                            {cell}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={pagingData?.Total || 0}
                    rowsPerPage={pagingData?.Limit || 10}
                    page={pagingData?.Offset / pagingData?.Limit || 0}
                    backIconButtonProps={{
                        'aria-label': 'previous page',
                        'data-test-id': 'previousPage',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'next page',
                        'data-test-id': 'nextPage',
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </>
        );
    };

    const dialog = () => {
        const onClickConfirm = () => {
            window.open(cellDownloadData.href, '_blank');
        };

        return (
            <ConfirmationPopup
                isLoading={!dialogDataReady}
                onCancel={closeDialog}
                onConfirm={onClickConfirm}
                isOpen={dialogOpen}
                title={
                    drillDownData?.DrillDown?.UpperNotes
                        ? drillDownData?.DrillDown?.UpperNotes
                        : 'Drilldown Data'
                }
                size="lg"
                customConfirmButtonText="Download to CSV"
                isConfirmButtonHidden={
                    !cellDownloadData?.href || !cellDownloadData?.download
                }
            >
                {!isFetchingDrillDownData && <DrillDownTable />}
            </ConfirmationPopup>
        );
    };

    const dialogError = () => {
        return (
            <InformationPopup
                isOpen={dialogErrorOpen}
                onConfirm={closeErrorDialog}
                title="Something went wrong..."
                description={dialogErrorMsg}
            />
        );
    };

    if (credibilityReport?.ReportMetadata && !loadingFailed) {
        return (
            <Paper className={styles.wrapper}>
                <div className={styles.title}>
                    <h1>{credibilityReport.ReportMetadata?.Title}</h1>
                </div>
                <p className={styles.details}>
                    {credibilityReport.ReportMetadata?.UpperNotes}
                </p>
                <br />
                {toggleDetails ? (
                    <div>
                        {credibilityReport.ReportMetadata?.Details ? (
                            <p className={styles.details}>
                                {credibilityReport.ReportMetadata?.Details}{' '}
                            </p>
                        ) : null}
                        <br />
                        {credibilityReport.ReportMetadata?.WhatToLookOutFor ? (
                            <div>
                                <p className={styles.details}>
                                    {
                                        credibilityReport.ReportMetadata
                                            .WhatToLookOutFor
                                    }
                                </p>
                                <br />
                            </div>
                        ) : null}
                        {credibilityReport.ReportMetadata?.WhatToLookOutFor ? (
                            <div>
                                <p className={styles.details}>
                                    {credibilityReport.ReportMetadata?.Tips}
                                </p>
                                <br />
                            </div>
                        ) : null}

                        <p className={styles.details}>
                            {credibilityReport.ReportMetadata?.LowerNotes}
                        </p>
                    </div>
                ) : (
                    ''
                )}
                <button
                    className="hdp-text-btn--regular padding--left-0"
                    onClick={toggleDetailsOpen}
                >
                    {toggleDetails ? 'Show less' : 'Show more'}
                </button>
                {dialogOpen ? dialog() : null}
                {dialogErrorOpen ? dialogError() : null}
                <div className={styles.reportWrapper}>
                    <div className={styles.report}>
                        <Tabs
                            className="hdp-override"
                            value={activeTab}
                            onChange={changeTab}
                            aria-label="credibility report tabs"
                        >
                            {credibilityReport.Tabs.map((tab, index) => (
                                <Tab
                                    disableRipple
                                    label={
                                        tab.Failures
                                            ? `${tab.TabName} (${tab.Failures})`
                                            : tab.TabName
                                    }
                                    key={crypto.randomUUID()}
                                    value={index}
                                    data-test-id={formatTestId(
                                        'tab',
                                        tab?.TabName,
                                    )}
                                    id={`credibility-report-tab-${index}`}
                                />
                            ))}
                        </Tabs>
                        <ResultsButton
                            className={`${styles.resultsButton} hdp-override margin--left-auto`}
                        />
                    </div>

                    <div className={styles.actionsBarWrapper}>
                        {tableLoading ? (
                            <div className={styles.loading}>
                                <CircularProgress aria-label="Circular loading animation" />
                            </div>
                        ) : null}
                        <div className={styles.actionsBar}>
                            <FormControlLabel
                                className={`${styles.switch} hdp-override`}
                                control={
                                    <Switch
                                        className="hdp-override"
                                        checked={isMoreData}
                                        onChange={toggleMoreData}
                                        data-test-id={formatTestId('show more')}
                                        inputProps={{
                                            'aria-label': 'show more data',
                                        }}
                                    />
                                }
                                label="Show more data"
                            />
                            <FormControlLabel
                                className={`${styles.switch} hdp-override`}
                                control={
                                    <Switch
                                        className="hdp-override"
                                        checked={compare}
                                        onChange={toggleCompare}
                                        data-test-id={formatTestId(
                                            'toggle',
                                            'compare',
                                        )}
                                        inputProps={{
                                            'aria-label': 'compare',
                                        }}
                                    />
                                }
                                label="Compare"
                            />
                            <ColumnSelectList
                                selectedValue={selectedPreviousSubmission}
                                placeholder="Select a submission"
                                closeDropDown={submissionSelected}
                                list={previousSubmissions}
                                columnNames={['ID', 'Date']}
                                columnKeys={['submissionId', 'uploadDate']}
                                disabled={!compare}
                            />
                        </div>
                    </div>
                    <div className={styles.innerWrapper}>
                        {credibilityReport.Tabs.map((tab, index) => (
                            <TabPanel
                                value={activeTab}
                                index={index}
                                key={crypto.randomUUID()}
                            >
                                <Table
                                    id={`simple-tabpanel-${index}`}
                                    data-test-id={formatTestId(
                                        'table',
                                        'credibility report',
                                    )}
                                >
                                    <TableHead className={styles.rowHeight}>
                                        <TableRow key={'subHeaders'}>
                                            {tab?.HeaderRow.map(cell => (
                                                <CredibilityCell
                                                    key={crypto.randomUUID()}
                                                    Label={cell.Label}
                                                    CellType="Label"
                                                    Colour="HeaderColour"
                                                    isRow={false}
                                                />
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tab?.DataRows.map(row => (
                                            <TableRow key={crypto.randomUUID()}>
                                                <CredibilityCell
                                                    key={crypto.randomUUID()}
                                                    Label={row.HeaderCell.Label}
                                                    Colour="HeaderColour"
                                                    CellType="Label"
                                                    isRow={false}
                                                />
                                                {row.Cells.map(cell => (
                                                    <CredibilityCell
                                                        key={crypto.randomUUID()}
                                                        {...cell.DataValues}
                                                        CellType="Data"
                                                        Label={cell.Label}
                                                        CellId={cell.Id}
                                                        Colour={cell.Colour}
                                                        isRow={true}
                                                        isExpanded={isMoreData}
                                                        clickHandler={
                                                            openDialog
                                                        }
                                                        canDrillDown={
                                                            hasDrillDownPerms() &&
                                                            credibilityReport
                                                                .ReportMetadata
                                                                .DrilldownAvailable
                                                        }
                                                    />
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TabPanel>
                        ))}
                    </div>
                </div>
                <p className={styles.details}>
                    {credibilityReport.ReportMetadata.LowerNotes}
                </p>
                <SymbolsKey />
            </Paper>
        );
    } else if (loadingFailed) {
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                p={5}
                alignItems={'center'}
            >
                {isFetchingReport ? (
                    <CircularProgress aria-label="Circular loading animation" />
                ) : (
                    <>
                        <Typography variant={'body'} paragraph align={'center'}>
                            Sorry about this, but it seems there&apos;s an error
                            retrieving this report right now.
                        </Typography>

                        <Box
                            display="flex"
                            flexDirection="row"
                            gap={2}
                            justifyContent="center"
                        >
                            <Button
                                variant={'outlined'}
                                size={'small'}
                                onClick={getReportData}
                            >
                                Try again
                            </Button>
                            <Button
                                variant={'outlined'}
                                size={'small'}
                                onClick={goBack}
                            >
                                Back
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        );
    } else {
        return (
            <Paper className={styles.credibilityReport}>
                <CircularProgress aria-label="Circular loading animation" />
            </Paper>
        );
    }
};

export default CredibilityReport;
