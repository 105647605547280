import React from 'react';
import { Clear } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';

export const CancelButton = ({ onClick, disabled = false, ...props }) => {
    return (
        <IconButton
            onClick={onClick}
            disabled={disabled}
            size="large"
            {...props}
        >
            <Clear size={'small'} />
        </IconButton>
    );
};

CancelButton.propTypes = {
    title: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};
