import { Params, RouteObject } from 'react-router-dom';
import AuthRoute from 'components/AuthRoute/AuthRoute';
import { ADDITIONAL_REPORTS } from 'constants/constants';
import OvtProcessing from 'pages/Collections/OnlineValidation/Steps/Processing/Processing';
import OvtQualityRule from 'pages/Collections/OnlineValidation/Steps/Reports/OvtQualityRule';
import OvtReports from 'pages/Collections/OnlineValidation/Steps/Reports/Reports';
import Approval from 'pages/Collections/Submission/Steps/Approval/Approval';
import AdditionalReport from 'pages/Collections/Submission/Steps/Reports/AdditionalReports/AdditionalReport';
import CredibilityReport from 'pages/Collections/Submission/Steps/Reports/Credibility/CredibilityReport/CredibilityReport';
import SubmissionQualityRule from 'pages/Collections/Submission/Steps/Reports/QualityRules/SubmissionQualityRule';
import SignOff from 'pages/Collections/Submission/Steps/SignOff/SignOff';
import Submit from 'pages/Collections/Submission/Steps/Submit/Submit';
import Upload from 'pages/Collections/Submission/Steps/Upload/Upload';
import { Submission } from 'pages/Collections/Submission/Submission';
import { CollectionPage as MonitoringCollection } from 'pages/Monitoring/Collection/CollectionPage';
import { Collections as MonitoringCollections } from 'pages/Monitoring/Collections/Collections';
import useProvider from 'queries/provider/useProvider';
import { Collection } from 'services/api/collections/types';
import useCollection from 'src/queries/collections/useCollection';

const monitoringSubmissionRoutes: RouteObject[] = [
    {
        path: ':collectionId/:instId/:submissionId',
        async lazy() {
            const { Submission } = await import(
                '../pages/Collections/Submission/Submission'
            );
            return { Component: Submission };
        },
        handle: {
            // When we declare a 'hook' alongside the 'crumb' property, we can pass the returned data from the hook to the 'crumb' function. See: components/Breadcrumbs/Breadcrumbs.jsx
            // TODO: Update type of data to be `ReturnType<typeof useProvider>` when the hooks are update to TypeScript in HDP-3564
            crumb: (data: {
                publishedName?: string;
                institutionId?: string;
            }) => {
                return `${data?.publishedName} (${data?.institutionId})`;
            },
            hook: useProvider,
            withoutLink: true,
        },

        children: [
            {
                path: 'upload',
                handle: {
                    crumb: 'Upload',
                },
                element: <Upload />,
            },
            {
                path: 'processing',
                handle: {
                    crumb: 'Processing',
                },
                async lazy() {
                    const { default: Processing } = await import(
                        '../pages/Collections/Submission/Steps/Processing/Processing'
                    );
                    return { Component: Processing };
                },
            },
            {
                path: 'reports',
                handle: {
                    crumb: 'Quality Assurance',
                },
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { default: Reports } = await import(
                                '../pages/Collections/Submission/Steps/Reports/Reports'
                            );
                            return { Component: Reports };
                        },
                    },
                    {
                        path: 'quality/:rule/:regulatorCode',
                        handle: {
                            crumb: 'Quality Rules',
                            withoutLink: true,
                        },
                        children: [
                            {
                                index: true,
                                element: <SubmissionQualityRule />,
                                handle: {
                                    crumb: 'Rule Failure',
                                    withoutLink: true,
                                },
                            },
                        ],
                    },
                    {
                        path: 'credibility/:reportCode/:instId',
                        handle: {
                            crumb: 'Credibility Reports',
                            withoutLink: true,
                        },
                        children: [
                            {
                                index: true,
                                element: <CredibilityReport />,
                                handle: {
                                    crumb: (params: Params<'reportCode'>) =>
                                        params?.reportCode,
                                    withoutLink: true,
                                },
                            },
                        ],
                    },
                    {
                        path: 'additional-reports/:reportCode',
                        handle: {
                            crumb: 'Additional Reports',
                            withoutLink: true,
                        },
                        children: [
                            {
                                index: true,
                                element: <AdditionalReport />,
                                handle: {
                                    crumb: (params: Params<'reportCode'>) => {
                                        if (params?.reportCode !== undefined)
                                            return ADDITIONAL_REPORTS[
                                                params.reportCode
                                            ];
                                    },
                                    withoutLink: true,
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: 'approval',
                handle: {
                    crumb: 'Approval',
                },
                element: <Approval />,
            },
            {
                path: 'submit',
                handle: {
                    crumb: 'Submit',
                },
                element: <Submit />,
            },
            {
                path: 'signoff',
                handle: {
                    crumb: 'Sign Off',
                },
                element: <SignOff />,
            },
        ],
    },
];

const monitoringOvtRoutes: RouteObject[] = [
    {
        element: <AuthRoute allowAllHesa />,
        children: [
            {
                path: ':collectionId/online-validation/:instId/:submissionId',
                element: <Submission isOvt />,
                handle: {
                    // When we declare a 'hook' alongside the 'crumb' property, we can pass the returned data from the hook to the 'crumb' function. See: components/Breadcrumbs/Breadcrumbs.jsx
                    // TODO: Update type of data to be `ReturnType<typeof useProvider>` when the hooks are update to TypeScript in HDP-3564
                    crumb: (data: {
                        publishedName?: string;
                        institutionId?: string;
                    }) => {
                        return `${data?.publishedName} (${data?.institutionId})`;
                    },
                    hook: useProvider,
                    withoutLink: true,
                },
                children: [
                    {
                        path: 'upload',
                        handle: {
                            crumb: 'Upload',
                        },
                        element: <Upload isOvt />,
                    },
                    {
                        path: 'processing',
                        handle: {
                            crumb: 'Processing',
                        },
                        element: <OvtProcessing />,
                    },
                    {
                        path: 'reports',
                        handle: {
                            crumb: 'Quality Assurance',
                        },
                        children: [
                            {
                                index: true,
                                element: <OvtReports />,
                            },
                            {
                                path: 'quality/:rule/:regulatorCode',
                                handle: {
                                    crumb: 'Quality Rules',
                                    withoutLink: true,
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <OvtQualityRule />,
                                        handle: {
                                            crumb: 'Rule Failure',
                                            withoutLink: true,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

const monitoringRoutes = [
    {
        element: (
            <AuthRoute
                allowAllHesa
                allowPrimaryRegulatorsOnly
                allowStatutoryCustomers="analyst"
            />
        ),

        children: [
            {
                path: 'monitoring',
                handle: {
                    crumb: 'Monitoring',
                },
                children: [
                    {
                        index: true,
                        element: <MonitoringCollections />,
                    },
                    {
                        path: ':reference',

                        handle: {
                            // When we declare a 'hook' alongside the 'crumb' property, we can pass the returned data from the hook to the 'crumb' function. See: components/Breadcrumbs/Breadcrumbs.jsx
                            // TODO: Update type of data to be `ReturnType<typeof useCollection>` when the hooks are update to TypeScript in HDP-3564
                            crumb: ({ data }: { data: Collection }) => {
                                return data?.name;
                            },
                            hook: useCollection,
                        },
                        children: [
                            {
                                index: true,
                                element: <MonitoringCollection />,
                            },
                            ...monitoringSubmissionRoutes,
                            ...monitoringOvtRoutes,
                        ],
                    },
                ],
            },
        ],
    },
];

export default monitoringRoutes;
