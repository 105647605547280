import React, { useEffect, useState } from 'react';
import { Box, Chip, NativeSelect } from '@mui/material';
import { Schema } from 'queries/schemas/types';
import useUpdateSchemaState from 'queries/schemas/useUpdateSchemaState';

import {
    CancelButton,
    EditButton,
    SaveButton,
} from '../../Collections/Buttons';

const modes = {
    DISPLAY: 1,
    EDITING: 2,
    SAVING: 3,
    ERROR: 4,
};

const { DISPLAY, EDITING, SAVING, ERROR } = modes;

const getSchemaState = (schema: Schema) => {
    const isEditable = schema.availableStates.length > 0;

    return {
        state: schema.state,
        availableStates: schema.availableStates,
        isEditable,
    };
};

interface SchemaStateProps {
    schema: Schema;
}

const SchemaState = ({ schema }: SchemaStateProps) => {
    const { state, availableStates, isEditable } = getSchemaState(schema);
    const [mode, setMode] = useState(DISPLAY);

    const defaultChoice = { id: '', name: 'Select state' };
    const { mutate: updateSchemaState } = useUpdateSchemaState();

    const [value, setValue] = useState(defaultChoice.id);

    useEffect(() => {
        if (mode === DISPLAY) {
            setValue(defaultChoice.id);
        }
    }, [defaultChoice.id, mode]);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setValue(event.target.value as string);
    };

    const handleEdit = () => setMode(EDITING);
    const handleSave = () => {
        updateSchemaState(
            {
                id: schema.id,
                stateId: value,
            },
            {
                onSuccess: () => {
                    setMode(DISPLAY);
                },
                onError: () => {
                    setMode(ERROR);
                },
            },
        );
    };

    const handleCancel = () => setMode(DISPLAY);

    switch (mode) {
        case DISPLAY:
            return (
                <Box
                    display={'flex'}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                >
                    <Chip variant={'outlined'} label={state.name} />
                    {isEditable && (
                        <EditButton
                            title="Change schema state"
                            onClick={handleEdit}
                        />
                    )}
                </Box>
            );
        default:
            return (
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <NativeSelect
                        value={value}
                        onChange={handleChange}
                        disabled={mode === SAVING}
                        error={mode === ERROR}
                        inputProps={{
                            'aria-label': 'States',
                        }}
                    >
                        {[defaultChoice, ...availableStates].map(
                            ({ id, name }) => (
                                <option key={id} value={id}>
                                    {name}
                                </option>
                            ),
                        )}
                    </NativeSelect>
                    <SaveButton
                        title="Save collection state"
                        onClick={handleSave}
                        loading={mode === SAVING}
                        disabled={mode === SAVING || value === defaultChoice.id}
                    />
                    <CancelButton
                        title="Cancel"
                        onClick={handleCancel}
                        disabled={mode === SAVING}
                    />
                </Box>
            );
    }
};

export default SchemaState;
