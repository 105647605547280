import { Close } from '@mui/icons-material';

import styles from './clearButton.module.scss';

interface ClearButtonProps {
    value: (string | number)[];
    handleClearClick: () => void;
}

const ClearButton = ({ value, handleClearClick }: ClearButtonProps) => {
    return (
        <span className={styles.counterMulti}>
            {value.length}
            <button
                onClick={handleClearClick}
                aria-label="Clear selection"
                tabIndex={0}
            >
                <Close fontSize="small" />
            </button>
        </span>
    );
};

export default ClearButton;
