import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Check, Close, HourglassEmpty } from '@mui/icons-material';
import classNames from 'classnames';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import { stepsAreEqual } from './StepFilters/utils';
import { useStepper } from './StepperContext';
import { StepFilter } from './types';

import styles from './step.module.scss';

type StepIconProps = {
    isActive: boolean;
    isComplete: boolean;
    isError: boolean;
};
type StepConnectorProps = {
    isActive: boolean;
    isComplete: boolean;
    isError: boolean;
};

const getIcon = (isActive: boolean, isComplete: boolean, isError: boolean) => {
    if (isError === true) {
        return <Close style={{ color: '#ffffff' }} fontSize="large" />;
    }
    if (isComplete === true) {
        return <Check style={{ color: '#ffffff' }} fontSize="large" />;
    }
    if (isActive === true) {
        return <HourglassEmpty style={{ color: '#ffffff' }} fontSize="large" />;
    }

    return null;
};

const StepIcon = ({ isActive, isComplete, isError }: StepIconProps) => {
    const icon = getIcon(isActive, isComplete, isError);

    const classes = classNames(styles.iconContainer, {
        [styles.iconActive]: isActive && !isError,
        [styles.iconComplete]: isComplete && !isError,
        [styles.iconError]: isError,
    });

    return <div className={classes}>{icon}</div>;
};

const StepConnector = ({
    isActive,
    isComplete,
    isError,
}: StepConnectorProps) => {
    const classes = classNames(styles.connector, {
        [styles.connectorActive]: isActive,
        [styles.connectorComplete]: isComplete,
        [styles.connectorError]: isError,
    });

    return <div className={classes} />;
};

type StepProps = {
    stepFilters: StepFilter;
    label: string;
};

const Step = ({ stepFilters, label }: StepProps) => {
    const location = useLocation();
    const stepperContext = useStepper();
    const {
        steps,
        updateStep,
        paths,
        navigateToStep,
        activeStep,
        isSubmissionSignedOff,
        latestMessage,
    } = stepperContext;

    const stepIndex = steps.findIndex(step => step.label === label);

    const isActive = stepFilters.checkIfIsActive(stepperContext);

    const isError = stepFilters.checkIfIsError(stepperContext);

    const isComplete =
        (stepFilters.checkIfIsComplete(stepperContext) && !isError) ||
        isSubmissionSignedOff;

    const nextStep = steps[stepIndex + 1];
    const [hasRedirected, setHasRedirected] = useState(false);

    const path = paths[label];
    const ariaCurrent = path === location.pathname ? 'step' : undefined;

    const canNavigate = isActive || isComplete || isError || stepIndex === 0;

    useEffect(() => {
        const isNextStepIsReady =
            !!nextStep?.isComplete ||
            !!nextStep?.isError ||
            !!nextStep?.isActive;

        const isAbleToProgressToNextStep =
            isComplete && isNextStepIsReady && !hasRedirected;

        const isWebsocketMessagePresent = !!latestMessage;

        // Redirect to the next step if the current step is complete and the next step is ready to be displayed
        // We only want to redirect when a websocket message is present to avoid redirecting when the user has refreshed the page or is loading the page on a specific step.
        // Using the latestMessage as a flag to determine if the websocket message has been received allows us to ensure that the automatic redirects will only occur when a submission is in-flight
        if (isAbleToProgressToNextStep && isWebsocketMessagePresent) {
            navigateToStep(nextStep?.label);
            setHasRedirected(true);
        }
    }, [nextStep, isComplete, hasRedirected, latestMessage, navigateToStep]);

    const linkProps = {
        to: canNavigate ? path : null,
        'data-test-id': formatTestId('link to', label),
    };

    useEffect(() => {
        const stepState = { label, isActive, isError, isComplete };
        if (!stepsAreEqual(steps[stepIndex], stepState)) {
            updateStep(stepIndex, stepState);
        }
    }, [isActive, isComplete, isError, label, stepIndex, steps, updateStep]);

    const linkClasses = classNames(styles.link, {
        [styles.disabled]: !canNavigate,
        [styles.currentStep]: label === activeStep.label,
    });

    return (
        path && (
            <li className={styles.step}>
                <StepIcon
                    isActive={isActive}
                    isComplete={isComplete}
                    isError={isError}
                />
                {linkProps.to === null ? (
                    <span
                        className={linkClasses}
                        data-test-id={linkProps['data-test-id']}
                        aria-current={ariaCurrent}
                    >
                        {label}
                    </span>
                ) : (
                    <NavLink
                        className={linkClasses}
                        to={linkProps.to}
                        data-test-id={linkProps['data-test-id']}
                        aria-current={ariaCurrent}
                    >
                        {label}
                    </NavLink>
                )}

                {stepIndex !== steps.length - 1 && (
                    <StepConnector
                        isActive={isActive}
                        isComplete={isComplete}
                        isError={isError}
                    />
                )}
            </li>
        )
    );
};

export default Step;
