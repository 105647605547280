import React, { useEffect, useState } from 'react';
import { Box, Chip, NativeSelect } from '@mui/material';
import { specStates } from 'constants/specifications';
import { SpecificationInterface } from 'queries/specifications/types';
import useUpdateSpecificationState from 'queries/specifications/useUpdateSpecificationState';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import {
    CancelButton,
    EditButton,
    SaveButton,
} from '../../../Collections/Buttons';

const getSpecStateLabel = (id: number) => {
    switch (id) {
        case specStates.CREATED:
            return 'Created';
        case specStates.DRAFT:
            return 'Draft';
        case specStates.AVAILABLE:
            return 'Available';
        case specStates.IN_USE:
            return 'In Use';
        default:
            return 'Unknown';
    }
};

const modes = {
    DISPLAY: 1,
    EDITING: 2,
    SAVING: 3,
    ERROR: 4,
};

interface SpecStateProps {
    specification: SpecificationInterface;
}

export const SpecState = ({ specification }: SpecStateProps) => {
    const { DISPLAY, EDITING, SAVING, ERROR } = modes;
    const { id, version, availableStates } = specification;
    const isEditable = availableStates.length > 0 ? true : false;
    const { mutate: updateSpecificationState } = useUpdateSpecificationState();

    const [mode, setMode] = useState(DISPLAY);

    const defaultChoice = { id: '', name: 'Select state' };

    const [value, setValue] = useState(defaultChoice.id);

    useEffect(() => {
        if (mode === DISPLAY) {
            setValue(defaultChoice.id);
        }
    }, [DISPLAY, defaultChoice.id, mode]);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setValue(event.target.value as string);
    };

    const handleEdit = () => setMode(EDITING);
    const handleCancel = () => {
        setMode(DISPLAY);
    };
    const handleSave = () => {
        setMode(SAVING);
        updateSpecificationState(
            {
                stateId: Number(value),
                id: id,
            },

            {
                onSuccess: () => {
                    setMode(DISPLAY);
                },
                onError: () => {
                    setMode(ERROR);
                },
            },
        );
    };

    switch (mode) {
        case DISPLAY:
            return (
                <Box
                    display={'flex'}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                >
                    {id && version && (
                        <Chip
                            variant={'outlined'}
                            label={getSpecStateLabel(specification.state.id)}
                            data-test-id={formatTestId(
                                'status',
                                `specification ${version}`,
                            )}
                        />
                    )}
                    {isEditable && (
                        <EditButton
                            title="Change specification state"
                            onClick={handleEdit}
                            data-test-id={formatTestId(
                                'editState',
                                `specification ${version}`,
                            )}
                        />
                    )}
                </Box>
            );
        default:
            return (
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <NativeSelect
                        id={formatTestId('select', `specification ${version}`)}
                        value={value}
                        onChange={handleChange}
                        disabled={mode === SAVING}
                        error={mode === ERROR}
                        inputProps={{
                            'aria-label': 'Specification status',
                        }}
                    >
                        {[defaultChoice, ...availableStates].map(
                            ({ id, name }) => (
                                <option key={id} value={id}>
                                    {name}
                                </option>
                            ),
                        )}
                    </NativeSelect>
                    <Box>
                        <SaveButton
                            title="Save specification state"
                            onClick={handleSave}
                            loading={mode === SAVING}
                            disabled={
                                mode === SAVING || value === defaultChoice.id
                            }
                            data-test-id={formatTestId(
                                'saveState',
                                `specification ${version}`,
                            )}
                        />
                        <CancelButton
                            title="Cancel"
                            onClick={handleCancel}
                            disabled={mode === SAVING}
                            data-test-id={formatTestId(
                                'cancelEdit',
                                `specification ${version}`,
                            )}
                        />
                    </Box>
                </Box>
            );
    }
};
