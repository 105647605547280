import { usePageTitle } from './PageTitleProvider';

type PageTitleProps = { title: string };

export const PageTitle = ({ title }: PageTitleProps) => {
    usePageTitle(title);

    return (
        <h1 id="heading" className="heading--h1">
            {title}
        </h1>
    );
};
