/**
 * Utility method for capitalizing strings
 *
 *   > capitalizeString('hello world')
 *   < "Hello World"
 *
 * @param {string} input
 * @return {string}
 */
export const capitalizeString = (input: string): string => {
    let output = '';

    const words = input.split(' ').map(word => {
        if (word) {
            return word[0].toUpperCase() + word.slice(1).toLowerCase();
        }
    });

    output = words.join(' ').trim() ?? '';

    return output;
};
