import { createBrowserRouter, redirect, RouteObject } from 'react-router-dom';
import { getUseAuthUserQueryOptions } from 'queries/auth/options';
import { queryClient } from 'queries/query-client';
import * as auth from 'services/auth';

import appRoutes from './app.routes';
import deliveryRoutes from './delivery.routes';
import irisRoutes from './iris.routes';
import managementRoutes from './management.routes';
import monitoringRoutes from './monitoring.routes';
import ovtRoutes from './ovt.routes';
import redirects from './redirects';
import submissionRoutes from './submission.routes';

const routes: RouteObject[] = [
    {
        path: '/',
        id: 'root',
        async lazy() {
            const App = (await import('pages/App/App')).default;
            return { Component: App };
        },
        loader: async () => {
            const user = await auth.getUser();

            if (!user) {
                await auth.signIn({ location: window.location.pathname });
            } else {
                const user = await queryClient.ensureQueryData(
                    getUseAuthUserQueryOptions(),
                );

                queryClient.setQueryData(
                    getUseAuthUserQueryOptions().queryKey,
                    user,
                );

                return user;
            }

            return {};
        },
        children: [
            ...appRoutes,
            ...monitoringRoutes,
            ...managementRoutes,
            ...submissionRoutes,
            ...deliveryRoutes,
            ...irisRoutes,
            ...ovtRoutes,
        ],
    },
    {
        path: '*',
        async lazy() {
            const { PageNotFound } = await import(
                'pages/PageNotFound/PageNotFound'
            );
            return { Component: PageNotFound };
        },
    },
    {
        path: '/callback/signin',
        loader: async () => {
            try {
                const user = await auth.signinCallback();
                await queryClient.ensureQueryData(getUseAuthUserQueryOptions());

                const { location } = (user.state as { location?: string }) ?? {
                    location: undefined,
                };
                return redirect(location || '/');
            } catch (error) {
                // The only error which should be caught here will be when a user is using an old / invalid oidc state to sign in. This is when the user's local storage is cleared and they try to sign in again using a stale state (via a bookmarked IDS redirect URL).
                // In this case, we want to redirect the user to the home page to start the authentication process again - this will clear the stale state. Ticket Reference: HDP-3936
                console.log('Stale login state, retrying', { error });
                await queryClient.invalidateQueries({
                    queryKey: getUseAuthUserQueryOptions().queryKey,
                });

                return redirect('/');
            }
        },
    },
    {
        path: '/callback/signout',
        loader: async () => {
            queryClient.invalidateQueries({
                queryKey: getUseAuthUserQueryOptions().queryKey,
            });
            return redirect('/');
        },
    },
    ...redirects,
];

const router = createBrowserRouter(routes);

export default router;
