export const processingConfig = [
    {
        name: 'File checking',
        displayStates: {
            error: {
                label: 'File checking failed',
                states: ['PROVISIONING_ERROR'],
            },
            passed: {
                label: 'File checking performed',
                states: ['PROVISIONED'],
            },
            waiting: {
                label: 'File checking',
                states: ['PROVISIONING'],
            },
        },
    },
    {
        name: 'Schema check',
        displayStates: {
            error: {
                label: 'Schema check failed',
                states: ['SCHEMA_CHECK_FAILED', 'SCHEMA_CHECK_ERROR'],
            },
            passed: {
                label: 'Schema check passed',
                states: ['SCHEMA_CHECK_PASSED'],
            },
            waiting: {
                label: 'Schema check',
                states: ['SCHEMA_CHECK'],
            },
        },
    },
    {
        name: 'Right to process',
        displayStates: {
            error: {
                label: 'Right to process check failed',
                states: ['RIGHT_TO_PROCESS_FAILED', 'RIGHT_TO_PROCESS_ERROR'],
            },
            passed: {
                label: 'Right to process check passed',
                states: ['RIGHT_TO_PROCESS_PASSED'],
            },
            waiting: {
                label: 'Right to process check',
                states: ['RIGHT_TO_PROCESS'],
            },
        },
    },
    {
        name: 'Enrichment',
        displayStates: {
            error: {
                label: 'Enrichment error',
                states: ['ENRICHMENT_ERROR', 'SAVING_ENTITIES_ERROR'],
            },
            passed: {
                label: 'Enrichment performed',
                states: ['ENRICHMENT_PASSED'],
            },
            waiting: {
                label: 'Enrichment',
                states: ['ENRICHMENT'],
            },
        },
    },
    {
        name: 'Quality rules',
        displayStates: {
            error: {
                label: 'Quality rules error',
                states: ['QUALITY_RULES_ERROR'],
            },
            passed: {
                label: 'Quality reports generated',
                states: ['QUALITY_RULES_PASSED'],
            },
            waiting: {
                label: 'Quality rules',
                states: ['QUALITY_RULES'],
            },
        },
    },
    {
        name: 'Quality processing complete',
        displayStates: {
            error: {
                label: '',
                states: [],
            },
            passed: {
                label: 'Quality processing complete',
                states: ['QUALITY_PROCESSING_COMPLETE'],
            },
            waiting: {
                label: 'Quality processing complete',
                states: ['QUALITY_PROCESSING_COMPLETE'],
            },
        },
    },
];
