import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Typography } from '@mui/material';

import Results from './Results';

const Reports = () => {
    const {
        data,
        collection,
        collectionId,
        submissionId,
        provider,
        setPageTitle,
    } = useOutletContext();

    useEffect(() => {
        setPageTitle('Quality Assurance');
    }, [setPageTitle]);

    return data.fileName ? (
        <React.Fragment>
            <h3>{data.fileName}</h3>
            <Results
                data={data}
                collection={collection}
                collectionId={collectionId}
                submissionId={submissionId}
                provider={provider}
            />
        </React.Fragment>
    ) : (
        <React.Fragment>
            <Typography>
                You have yet to upload a file. Please upload one on the upload
                step.
            </Typography>
        </React.Fragment>
    );
};

export default Reports;
