import { Params, RouteObject } from 'react-router-dom';

import AuthRoute from '../components/AuthRoute/AuthRoute';
import { ADDITIONAL_REPORTS } from '../constants/constants';
import Approval from '../pages/Collections/Submission/Steps/Approval/Approval';
import AdditionalReport from '../pages/Collections/Submission/Steps/Reports/AdditionalReports/AdditionalReport';
import CredibilityReport from '../pages/Collections/Submission/Steps/Reports/Credibility/CredibilityReport/CredibilityReport';
import SubmissionQualityRule from '../pages/Collections/Submission/Steps/Reports/QualityRules/SubmissionQualityRule';
import SignOff from '../pages/Collections/Submission/Steps/SignOff/SignOff';
import Submit from '../pages/Collections/Submission/Steps/Submit/Submit';
import Upload from '../pages/Collections/Submission/Steps/Upload/Upload';

const submissionRoutes: RouteObject[] = [
    {
        element: <AuthRoute allowAllProviders />,
        children: [
            {
                path: 'submissions',
                handle: {
                    crumb: 'Manage Submissions',
                },
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { Submissions } = await import(
                                '../pages/Collections/Submission/Submissions'
                            );
                            return { Component: Submissions };
                        },
                    },
                    {
                        path: ':collectionReference/activity-log',
                        async lazy() {
                            const module = await import(
                                '../components/ActivityLog/ProviderActivityLog/ProviderActivityLog'
                            );
                            return { Component: module.default };
                        },
                        handle: {
                            crumb: 'Activity Log',
                        },
                    },
                    {
                        path: ':collectionId/:submissionId',
                        async lazy() {
                            const { Submission } = await import(
                                '../pages/Collections/Submission/Submission'
                            );
                            return {
                                Component: Submission,
                            };
                        },
                        handle: {
                            crumb: (params: Params<'submissionId'>) => {
                                if (params?.submissionId !== undefined)
                                    return params.submissionId.slice(-8);
                            },
                            withoutLink: true,
                        },
                        children: [
                            {
                                path: 'upload',
                                handle: {
                                    crumb: 'Upload',
                                },
                                element: <Upload />,
                            },
                            {
                                path: 'processing',
                                handle: {
                                    crumb: 'Processing',
                                },
                                async lazy() {
                                    const { default: Processing } =
                                        await import(
                                            '../pages/Collections/Submission/Steps/Processing/Processing'
                                        );
                                    return { Component: Processing };
                                },
                            },
                            {
                                path: 'reports',
                                handle: {
                                    crumb: 'Quality Assurance',
                                },
                                children: [
                                    {
                                        index: true,
                                        async lazy() {
                                            const { default: Reports } =
                                                await import(
                                                    '../pages/Collections/Submission/Steps/Reports/Reports'
                                                );
                                            return { Component: Reports };
                                        },
                                    },
                                    {
                                        path: 'quality/:rule/:regulatorCode',
                                        handle: {
                                            crumb: 'Quality Rules',
                                            withoutLink: true,
                                        },
                                        children: [
                                            {
                                                index: true,
                                                element: (
                                                    <SubmissionQualityRule />
                                                ),
                                                handle: {
                                                    crumb: 'Rule Failure',
                                                    withoutLink: true,
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        path: 'credibility/:reportCode/:instId',
                                        handle: {
                                            crumb: 'Credibility Reports',
                                            withoutLink: true,
                                        },
                                        children: [
                                            {
                                                index: true,
                                                element: <CredibilityReport />,
                                                handle: {
                                                    crumb: (
                                                        params: Params<'reportCode'>,
                                                    ) => params?.reportCode,
                                                    withoutLink: true,
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        path: 'additional-reports/:reportCode',
                                        handle: {
                                            crumb: 'Additional Reports',
                                            withoutLink: true,
                                        },
                                        children: [
                                            {
                                                index: true,
                                                element: <AdditionalReport />,
                                                handle: {
                                                    crumb: (
                                                        params: Params<'reportCode'>,
                                                    ) => {
                                                        if (
                                                            params?.reportCode !==
                                                            undefined
                                                        )
                                                            return ADDITIONAL_REPORTS[
                                                                params
                                                                    .reportCode
                                                            ];
                                                    },
                                                    withoutLink: true,
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'approval',
                                handle: {
                                    crumb: 'Approval',
                                },
                                element: <Approval />,
                            },
                            {
                                path: 'submit',
                                handle: {
                                    crumb: 'Submit',
                                },
                                element: <Submit />,
                            },
                            {
                                path: 'signoff',
                                handle: {
                                    crumb: 'Sign Off',
                                },
                                element: <SignOff />,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export default submissionRoutes;
