import { ValidationError } from 'fast-xml-parser';
import XmlValidatorWorker from 'src/workers/xmlValidator?worker';

type XmlValidatorResult = true | ValidationError;

type XmlValidatorMessage = {
    success: boolean;
    result: XmlValidatorResult;
    error?: unknown;
};

export const validateXML = (
    fileContent: string | ArrayBuffer | null,
): Promise<XmlValidatorResult> => {
    return new Promise((resolve, reject) => {
        const worker = new XmlValidatorWorker();

        worker.onmessage = (e: MessageEvent<XmlValidatorMessage>) => {
            if (e.data.success) {
                resolve(e.data.result);
            } else {
                reject(e.data.error);
            }
        };

        worker.onerror = error => {
            reject(error.message);
        };

        worker.postMessage(fileContent);
    });
};
