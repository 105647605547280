import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Button } from '@mui/material';
import { RestrictAccess } from 'components/User/RestrictAccess/RestrictAccess';
import useUploadSubmission from 'queries/files/useUploadSubmission';

import { SubmissionOutletContextValue } from '../../types';

import UploadProgress from './UploadProgress';

import styles from './upload.module.scss';

interface UploadingMessageProps {
    submissionUuid: string;
    collectionId: string;
    instId: string;
    fileName: string;
    uploadProgress: number;
    isOvt?: boolean;
}

export default function UploadingMessage({
    submissionUuid,
    collectionId,
    instId,
    fileName,
    uploadProgress,
    isOvt = false,
}: UploadingMessageProps) {
    const [isCancelling, setIsCancelling] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false);
    const { getSubmission } = useOutletContext<SubmissionOutletContextValue>();
    const { cancelUpload } = useUploadSubmission();

    const handleCancel = () => {
        setIsCancelling(true);
        cancelUpload(
            {
                submissionUuid,
                fileName,
                collectionId,
                instId,
                isOvt,
            },
            {
                onSuccess: () => {
                    setIsCancelled(true);
                    setIsCancelling(false);
                    getSubmission();
                },
                onError: () => {
                    setIsCancelling(false);
                    setIsCancelled(false);
                    getSubmission();
                },
            },
        );
    };

    return (
        <>
            <div className={styles.uploadActions}>
                <span>Uploading</span>
                <UploadProgress progress={uploadProgress} />
                <div className={styles.or} aria-live="polite">
                    Please wait while we upload your file.
                </div>
                <RestrictAccess
                    allowPermissions={
                        !isOvt
                            ? ['collections.cancel-upload']
                            : ['online-validation.cancel-upload-ovt']
                    }
                >
                    <Button
                        onClick={handleCancel}
                        disabled={isCancelled || isCancelling}
                    >
                        Cancel
                    </Button>
                </RestrictAccess>
            </div>
        </>
    );
}
