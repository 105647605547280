import {
    Box,
    Button,
    Chip,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { usePageTitle } from 'components';
import Loading from 'components/Loading/Loading';
import { DateTime } from 'luxon';
import useRulesets from 'queries/rulesets/useRulesets';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import styles from './rulesets.module.scss';

const formatDate = (date: string) =>
    DateTime.fromISO(date).toFormat('dd/LL/yyyy HH:mm');

const handleTryAgain = () => {
    window.location.reload();
};

const Rulesets = () => {
    const { data, status } = useRulesets({ state: [] });
    usePageTitle('Ruleset Versions');

    switch (status) {
        case 'pending':
            return <Loading />;

        case 'success':
            return (
                <Paper square>
                    <Table data-test-id={formatTestId('table', 'rulesets')}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Ruleset</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Last updated</TableCell>
                                <TableCell>Last updated by</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map(ruleset => (
                                <TableRow
                                    hover
                                    className={styles.row}
                                    key={ruleset.id}
                                >
                                    <TableCell width={'25%'}>
                                        Ruleset {ruleset.version}
                                    </TableCell>
                                    <TableCell width={'25%'}>
                                        <Chip
                                            variant={'outlined'}
                                            label={ruleset.state.name}
                                        />
                                    </TableCell>
                                    <TableCell width={'25%'}>
                                        {formatDate(ruleset.updatedUtc)}
                                    </TableCell>
                                    <TableCell width={'25%'}>
                                        {ruleset.updatedBy}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            );

        case 'error':
            return (
                <Paper>
                    <Box
                        p={4}
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                    >
                        <Typography variant={'body2'} paragraph>
                            There was a problem loading the list of rulesets.
                        </Typography>
                        <Button
                            variant={'outlined'}
                            size={'small'}
                            onClick={handleTryAgain}
                        >
                            Try again
                        </Button>
                    </Box>
                </Paper>
            );

        default:
            return null;
    }
};

export default Rulesets;
