import decode from 'jwt-decode';
import { token as tokenApi } from 'services/api';
import * as auth from 'services/auth';
import { token as tokenStorage } from 'services/storage';
import { AuthUser, DecodedJwt } from 'types/auth';

export const getUser = async () => {
    const user = await auth.getUser();

    if (!user) {
        throw new Error('No user found');
    }

    if (user.expired) {
        await auth.signIn({ location: window.location.pathname });
        return null;
    }

    try {
        const { token } = await tokenApi.exchangeToken({
            token: user?.access_token,
        });
        tokenStorage.set(token);
        const decodedToken = decode(token) as DecodedJwt;
        return { ...user, decodedToken } as AuthUser;
    } catch (error) {
        // If the token exchange fails, request that the user signs in again to get a new token from IDS
        tokenStorage.clear();
        await auth.signIn({ location: window.location.pathname });
        return null;
    }
};
