import stringToCamelCase from '../stringToCamelCase/stringToCamelCase';

/**
 * Utility method for formatting data-test-ids
 * @see {@link https://jiscdev.atlassian.net/wiki/spaces/JDF/pages/4346544133/UI+-+Naming+convention|Naming convention docs} for more information
 * @example
 * formatTestId('link to', 'Delivery files')
 * // Returns "linkTo_deliveryFiles"
 */
export const formatTestId = (action: string, context: string = ''): string => {
    // Ensure the first letter is always lower

    const idSections = [
        stringToCamelCase(action),
        stringToCamelCase(context),
    ].filter(i => i);
    return idSections ? idSections.join('_') : '';
};
