import React, { lazy, Suspense } from 'react';
import { useParams } from 'react-router-dom';

const PGRIN = lazy(() => import('./PGRTransfersReports/PGRTransfersInReport'));
const PGROUT = lazy(
    () => import('./PGRTransfersReports/PGRTransfersOutReport'),
);
const NSS = lazy(() => import('./NSSReport/NSSReport'));
const CCA = lazy(() => import('./CCAReport/CCAReport'));
const CONPOP = lazy(
    () => import('./ContinuityPopulationReport/ContinuityPopulationReport'),
);
const FREQCOUNT = lazy(
    () => import('./FrequencyCountsReport/FrequencyCountsReport'),
);
const IRIS = lazy(() => import('./IrisReport/IrisReport'));

const COMPARISON = lazy(() => import('./ComparisonReport/ComparisonReport'));

export const reports = {
    PGRIN,
    PGROUT,
    NSS,
    CCA,
    CONPOP,
    FREQCOUNT,
    IRIS,
    COMPARISON,
};

const AdditionalReport = () => {
    const { reportCode } = useParams();

    const Report = reports[reportCode];

    return (
        <Suspense fallback={<p>Loading...</p>}>
            <Report />
        </Suspense>
    );
};

export default AdditionalReport;
