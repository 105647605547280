import { redirect, RouteObject } from 'react-router-dom';

import { redirectToPathWithParams } from './utils';

const redirects: RouteObject[] = [
    {
        path: 'admin/*',
        loader: () => redirect('/'),
    },
    {
        path: 'collection/:collectionId/submission/:submissionId/*',
        loader: ({ params }) =>
            redirectToPathWithParams(
                'collection/:collectionId/submission/:submissionId/*',
                params,
            ),
    },
    {
        path: 'collection/:collectionId/online-validation/:submissionId/*',
        loader: ({ params }) =>
            redirectToPathWithParams(
                '/online-validation/:collectionId/:submissionId',
                params,
            ),
    },
    {
        path: 'collection/:collectionReference/activityLog',
        loader: ({ params }) =>
            redirectToPathWithParams(
                '/collection/:collectionReference/activity-log',
                params,
            ),
    },
    {
        path: 'management/deliveryConfig',
        loader: () => redirect('/management/delivery-config'),
    },
    {
        path: 'management/deliveryConfig/create',
        loader: () => redirect('/management/delivery-config/create'),
    },
    {
        path: 'management/bulk-reprocessing/:collectionId/:reference',
        loader: ({ params }) =>
            redirectToPathWithParams(
                '/management/collections/bulk-reprocessing/:collectionId/:reference',
                params,
            ),
    },
    {
        path: 'monitoring/reference/:reference',
        loader: ({ params }) =>
            redirectToPathWithParams('/monitoring/:reference', params),
    },
];

export default redirects;
