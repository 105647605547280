import { ButtonHTMLAttributes, ReactNode } from 'react';

import styles from './GhostButton.module.scss';

interface GhostButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: ReactNode;
    'data-test-id'?: string;
}

export default function GhostButton({
    children,
    ...buttonProps
}: GhostButtonProps) {
    return (
        <button {...buttonProps} className={styles.ghostButton}>
            {children}
        </button>
    );
}
