import React from 'react';

import styles from './addConjunction.module.scss';

const conjunctionTypes = {
    O: 'or',
    A: 'and',
};

const AddConjunction =
    (type: 'O' | 'A') =>
    (
        output: React.ReactElement | null,
        tolerance: React.ReactElement | null,
    ) => {
        return (
            <>
                {output}
                <span key={type} className={styles.separatorConjunction}>
                    {conjunctionTypes[type]}
                </span>
                {tolerance}
            </>
        );
    };

export default AddConjunction;
