import React, { useEffect, useRef, useState } from 'react';
import { Link } from '@mui/material';
import classNames from 'classnames';
import { specStates } from 'constants/specifications';
import { SpecificationInterface } from 'queries/specifications/types';
import useUpdateSpecificationState from 'queries/specifications/useUpdateSpecificationState';

import styles from './moveSpecificationToDraft.module.scss';

const states = {
    DISPLAY: 1,
    UPDATING: 2,
    ERROR: 3,
};

interface MoveSpecificationToDraftProps {
    spec: SpecificationInterface;
    message: string;
    children: React.ReactNode;
}

const MoveSpecificationToDraft = ({
    spec,
    message,
    children,
}: MoveSpecificationToDraftProps) => {
    const [state, setState] = useState(states.DISPLAY);
    const { mutate: updateSpecState } = useUpdateSpecificationState();
    const mounted = useRef(true);

    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, []);

    const moveToDraft = () => {
        setState(states.UPDATING);

        updateSpecState(
            { id: spec.id, stateId: specStates.DRAFT },
            {
                onSuccess: () => {
                    if (mounted.current) {
                        setState(states.DISPLAY);
                    }
                },
                onError: () => setState(states.ERROR),
            },
        );
    };

    switch (spec.state.id) {
        case specStates.DRAFT:
            return <div className={styles.container}>{children}</div>;

        case specStates.AVAILABLE:
            return (
                <div className={styles.container}>
                    <span
                        className={classNames(styles.message, {
                            [styles.disabledButton]: state === states.DISPLAY,
                        })}
                    >
                        {state === states.ERROR
                            ? 'There was an error moving the specification to draft.'
                            : message}
                    </span>
                    {state === states.UPDATING && (
                        <span className={styles.message}>
                            Moving specification to draft...
                        </span>
                    )}
                    {state === states.ERROR && (
                        <Link
                            component={'button'}
                            variant={'body2'}
                            onClick={moveToDraft}
                        >
                            Try again
                        </Link>
                    )}
                    {state === states.DISPLAY && (
                        <Link
                            component={'button'}
                            variant={'body2'}
                            onClick={moveToDraft}
                        >
                            Move specification to draft
                        </Link>
                    )}
                </div>
            );

        default:
            return null;
    }
};

export default MoveSpecificationToDraft;
