import React, { useCallback } from 'react';
import { InputAdornment, TextField } from '@mui/material';

import { useEditTolerances } from '../useEditTolerances';

import styles from './numberInput.module.scss';

interface NumberInputProps {
    id: string;
    suffix?: string | null;
    inputType?: 'number' | 'text';
    helperText: string;
    testId: string;
}

// TODO: Swap this out for something simpler in the future, like a reusable input component that can be used for all inputs. The EditTolerancesField component is a bit of a mess.
// This TODO is left as a reminder here in case there is a bug with the EditTolerancesField component / this component.. then don't just fix the bug, but speak with the team
// and consider replacing the whole thing.
const NumberInput = ({
    id,
    suffix = null,
    inputType = 'number',
    helperText,
    testId,
}: NumberInputProps) => {
    const { onToleranceChange, getToleranceInput, validateInput } =
        useEditTolerances();
    const { error, value } = getToleranceInput(id);

    const resolveToleranceValue = useCallback(
        (value: string | number) => {
            switch (inputType) {
                case 'text':
                    return value
                        ? value
                              .toString()
                              .split('.')
                              .map((el, i) =>
                                  i ? el.split('').slice(0, 2).join('') : el,
                              )
                              .join('.')
                        : '';
                default:
                    return value ? Number.parseInt(value.toString(), 10) : '';
            }
        },
        [inputType],
    );

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onToleranceChange({
                id,
                value: resolveToleranceValue(e.target.value),
            });
        },
        [onToleranceChange, resolveToleranceValue, id],
    );

    const handleBlur = useCallback(
        () => validateInput(id),
        [validateInput, id],
    );

    return React.useMemo(() => {
        const InputProps = {
            ...(suffix
                ? {
                      endAdornment: (
                          <InputAdornment position={'end'}>
                              {suffix}
                          </InputAdornment>
                      ),
                  }
                : {}),
        };

        return (
            <TextField
                className={styles.textField}
                id={id}
                type={inputType}
                error={error}
                helperText={error && helperText}
                InputProps={InputProps}
                size={'small'}
                variant={'outlined'}
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
                data-test-id={testId}
            />
        );
    }, [
        error,
        handleBlur,
        handleChange,
        id,
        value,
        inputType,
        suffix,
        helperText,
        testId,
    ]);
};

export default NumberInput;
