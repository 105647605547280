import { useEffect, useState } from 'react';
import React from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { ExpandMoreRounded } from '@mui/icons-material';
import classNames from 'classnames';

import styles from './accordion.module.scss';

interface AccordionProps {
    id: string;
    children: React.ReactNode;
    title: string;
    type?: 'primary' | 'secondary';
    description?: string;
    isOpenByDefault?: boolean;
    dataTestId: string;
}

const Accordion = ({
    id,
    children,
    title,
    type = 'primary',
    description,
    isOpenByDefault = false,
    dataTestId,
}: AccordionProps) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [isOpen, setIsOpen] = useState(
        isOpenByDefault || searchParams.get(id) === 'true',
    );

    useEffect(() => {
        const newSearchParams = createSearchParams(searchParams);

        if (isOpen) {
            newSearchParams.set(id, 'true');
        } else {
            newSearchParams.delete(id);
        }

        if (newSearchParams.toString() !== searchParams.toString()) {
            setSearchParams(newSearchParams, { replace: true });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, searchParams]);

    return (
        <div className={styles.root}>
            <div role="heading" className={styles.header}>
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className={classNames(styles.headerButton, {
                        [styles.accordionClosed]: !isOpen,
                        [styles.accordionOpen]: isOpen,
                        [styles.primary]: type === 'primary',
                        [styles.secondary]: type === 'secondary',
                    })}
                    data-test-id={dataTestId}
                    aria-expanded={isOpen}
                    aria-controls={id}
                >
                    <div className={styles.iconAndTitle}>
                        <ExpandMoreRounded
                            className={classNames(styles.icon, {
                                [styles.primary]: type === 'primary',
                                [styles.secondary]: type === 'secondary',
                            })}
                        />
                        <div className={styles.titleAndDescription}>
                            <p
                                className={classNames(styles.title, {
                                    [styles.primary]: type === 'primary',
                                    [styles.secondary]: type === 'secondary',
                                })}
                            >
                                {title}
                            </p>
                            {description && description.length > 0 && (
                                <p className={styles.description}>
                                    {description}
                                </p>
                            )}
                        </div>
                    </div>
                </button>
            </div>
            {isOpen && (
                <div
                    className={classNames(styles.panel, {
                        [styles.primary]: type === 'primary',
                        [styles.secondary]: type === 'secondary',
                    })}
                    id={id}
                >
                    {children}
                </div>
            )}
        </div>
    );
};

export default Accordion;
