import React, { PropsWithChildren } from 'react';

type TabPanelProps = {
    value: number | string;
    index: number | string;
};

const TabPanel = ({
    children,
    value,
    index,
}: PropsWithChildren<TabPanelProps>) => {
    return (
        <div role="tabpanel" hidden={value !== index}>
            {value === index && children}
        </div>
    );
};

TabPanel.displayName = 'TabPanel';

export default TabPanel;
