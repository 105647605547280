import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { usePageTitle } from 'components';
import Loading from 'components/Loading/Loading';
import { DateTime } from 'luxon';
import { Schema } from 'queries/schemas/types';
import useSchemas from 'queries/schemas/useSchemas';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import SchemaState from './SchemaState';

import styles from './schemas.module.scss';

const formatDate = (date: string) =>
    DateTime.fromISO(date).toFormat('dd/LL/yyyy HH:mm');

const onTryAgain = () => {
    window.location.reload();
};

const Schemas = () => {
    const { data: schemas, status } = useSchemas({ state: [] });
    usePageTitle('Schema Versions');

    switch (status) {
        case 'pending':
            return <Loading />;

        case 'success':
            return (
                <Paper square>
                    <Table data-test-id={formatTestId('table', 'schema')}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Schema</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Last updated</TableCell>
                                <TableCell>Last updated by</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {schemas.map((schema: Schema) => (
                                <TableRow
                                    key={schema.id}
                                    hover
                                    className={styles.row}
                                >
                                    <TableCell width={'25%'}>
                                        Schema {schema.version}
                                    </TableCell>
                                    <TableCell width={'25%'}>
                                        <SchemaState schema={schema} />
                                    </TableCell>
                                    <TableCell width={'25%'}>
                                        {formatDate(schema.updatedUtc)}
                                    </TableCell>
                                    <TableCell width={'25%'}>
                                        {schema.updatedBy}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            );

        case 'error':
            return (
                <Paper>
                    <Box
                        p={4}
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                    >
                        <Typography variant={'body2'} paragraph>
                            There was a problem loading the list of schemas.
                        </Typography>
                        <Button
                            variant={'outlined'}
                            size={'small'}
                            onClick={onTryAgain}
                        >
                            Try again
                        </Button>
                    </Box>
                </Paper>
            );

        default:
            return null;
    }
};

export default Schemas;
