import { RouteObject } from 'react-router-dom';
import AuthRoute from 'components/AuthRoute/AuthRoute';

const deliveryRoutes: RouteObject[] = [
    {
        element: (
            <AuthRoute
                allowAllHesa
                allowStatutoryCustomers={['data-delivery']}
            />
        ),
        path: 'delivery',
        handle: {
            crumb: 'Deliveries',
        },
        children: [
            {
                index: true,
                async lazy() {
                    const { Delivery } = await import(
                        'pages/Delivery/Delivery/Delivery'
                    );
                    return { Component: Delivery };
                },
            },
            {
                element: <AuthRoute allowAllHesa />,
                path: 'downloads-log',
                handle: {
                    crumb: 'Downloads Log',
                },
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { DeliveryLog } = await import(
                                'pages/Delivery/DeliveryLog/DeliveryLog'
                            );
                            return { Component: DeliveryLog };
                        },
                    },
                ],
            },
        ],
    },
];

export default deliveryRoutes;
