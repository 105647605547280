import * as filesApi from '../../../services/api/files';

interface Submission {
    uuid: string;
    provider: {
        institutionId: string;
        regulatorCode: string;
    };
    state: string;
    awaitingParty: null | {
        id: string;
    };
}

interface DownloadResponse {
    url: string;
}

export const requestGracefulFailuresDownload = async (
    submissionUuid: string,
): Promise<void> => {
    const { url }: DownloadResponse = await filesApi.downloadGracefulFailures(
        submissionUuid,
    );
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const filterSubmissions = (
    submissions: Submission[],
    selectedProviders: string[],
    selectedStatuses: string[],
    selectedRegulators: string[],
    selectedAwaitingAction?: string[],
): Submission[] => {
    return submissions.filter(submission => {
        const providerMatch =
            selectedProviders.length === 0 ||
            selectedProviders.includes(submission.provider.institutionId);

        const statusMatch =
            selectedStatuses.length === 0 ||
            selectedStatuses.includes(submission.state);

        const regulatorMatch =
            selectedRegulators.length === 0 ||
            selectedRegulators.includes(submission.provider.regulatorCode);

        // if filterSubmissions is being called in the OVT summary, we don't need to check the awaiting action
        // so we return here
        if (!selectedAwaitingAction) {
            return providerMatch && statusMatch && regulatorMatch;
        }

        // if filterSubmissions is being called in the non OVT summary, we also need to check the awaiting action
        const awaitingActionMatch =
            selectedAwaitingAction.length === 0 ||
            (submission.awaitingParty !== null &&
                selectedAwaitingAction.includes(submission.awaitingParty.id));

        return (
            providerMatch &&
            statusMatch &&
            regulatorMatch &&
            awaitingActionMatch
        );
    });
};
