import React from 'react';

import ConfirmationPopup from '../ConfirmationPopup/ConfirmationPopup';

/*
 * InformationPopup is a ConfirmationPopup that only has a single button, and is used to display information to the user.
 */

type InformationPopupProps = {
    isOpen: boolean;
    title: string;
    onConfirm: () => void;
    description?: string;
    customCancelButtonText?: string;
    children?: React.ReactNode;
};

const InformationPopup = ({
    isOpen,
    title,
    onConfirm,
    description,
    customCancelButtonText,
    children,
}: InformationPopupProps) => {
    return (
        <ConfirmationPopup
            isOpen={isOpen}
            title={title}
            onConfirm={onConfirm}
            onCancel={onConfirm}
            customCancelButtonText={
                customCancelButtonText ? customCancelButtonText : 'DISMISS'
            }
            description={description}
            isConfirmButtonHidden={true}
        >
            {children}
        </ConfirmationPopup>
    );
};

export default InformationPopup;
