import { ImCross } from 'react-icons/im';
import { Link } from 'react-router-dom';

import { useStepper } from '../Stepper/StepperContext';

import styles from './upload.module.scss';

const UploadError = () => {
    const { paths } = useStepper();

    return (
        <>
            <div className={styles.uploadActions}>
                <ImCross className={`${styles.icon} ${styles.red}`} />

                <span>Upload failed</span>

                <div className={styles.or}>
                    We were unable to upload your file or the upload was
                    cancelled.
                </div>
                <Link className={styles.errorLink} to={paths.Submissions}>
                    Back
                </Link>
            </div>
        </>
    );
};

export default UploadError;
