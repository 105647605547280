import stringToCamelCase from '../stringToCamelCase/stringToCamelCase';

/**
 * Utility method for turning JSON keys into camelCase when using JSON.parse
 *
 * @example
 * const someJsonString = '{"SomeProperty": "value"}'
 * JSON.parse(someJsonString, jsonCamelCaseResolver)
 * // Returns { someProperty: "value" }
 */
function jsonCamelCaseResolver(
    this: Record<string, unknown>,
    key: string,
    value: unknown,
) {
    const camelCaseKey = stringToCamelCase(key);
    if (key === camelCaseKey) return value;
    else {
        this[camelCaseKey] = value;
    }
}

export default jsonCamelCaseResolver;
