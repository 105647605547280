import { fileStatusById } from 'src/constants/FileStatus';

import { CurrentState, StateFilter, StateHistory, StepObject } from '../types';

export const currentStateMatchesFilters = (
    stateFilters: StateFilter[],
    currentState: CurrentState,
): boolean => stateFilters.some(filter => filter?.id === currentState?.id);

export const isStepComplete = (
    stateFilters: StateFilter[],
    stateHistory: StateHistory = [],
): boolean => {
    const stateHistoryIds = stateHistory.map(state => state?.StatusId);
    const stateFilterIds = stateFilters.map(state => state?.id);
    return stateFilterIds.some(id => stateHistoryIds.includes(id));
};

export const stateHistoryIncludesFilters = (
    stateFilters: StateFilter[],
    stateHistory: StateHistory = [],
): boolean => {
    const stateHistoryIds = stateHistory.map(state => state?.StatusId);
    const stateFilterIds = stateFilters.map(state => state?.id);
    return stateFilterIds.some(id => stateHistoryIds.includes(id));
};

export const currentStateIsFurtherOrEqualToFilter = (
    stateFilter: StateFilter,
    currentState: CurrentState,
): boolean => {
    if (!currentState) return false;
    const currentStateOrder = fileStatusById(currentState.id).order;
    return currentStateOrder >= stateFilter.order;
};

export const mergeSteps = (
    steps: StepObject[],
    newStep: StepObject,
): StepObject[] => {
    const newSteps = [...steps];
    const stepIndex = newSteps.findIndex(step => step.label === newStep.label);

    if (stepIndex !== -1) {
        newSteps[stepIndex] = newStep;
    } else {
        newSteps.push(newStep);
    }

    return newSteps;
};

export const stepsAreEqual = (
    step: StepObject,
    comparison: StepObject,
): boolean => {
    return JSON.stringify(step) === JSON.stringify(comparison);
};
