import { RouteObject } from 'react-router-dom';

const appRoutes: RouteObject[] = [
    {
        index: true,
        async lazy() {
            const { Dashboard } = await import('pages/Dashboard/Dashboard');
            return { Component: Dashboard };
        },
    },
    {
        path: 'accessibility',
        async lazy() {
            const { Accessibility } = await import(
                'pages/Accessibility/Accessibility'
            );
            return { Component: Accessibility };
        },
        handle: {
            crumb: 'Accessibility',
        },
    },
    {
        path: '*',
        async lazy() {
            const { PageNotFound } = await import(
                'pages/PageNotFound/PageNotFound'
            );
            return { Component: PageNotFound };
        },
    },
];

export default appRoutes;
