import Loading from 'components/Loading/Loading';
import TabPanel from 'components/Tabs/TabPanel';
import { TABLE_PARAMS_IDS } from 'src/constants/constants';
import useTableParams from 'src/hooks/useTableParams/useTableParams';

import { uiStates } from '../queries';

import Filters from './Filters/Filters';
import { useProviderSubmissions } from './hooks';
import { filterSubmissions } from './utils';
import { SubmissionSummaryTable } from '.';

export const SubmissionSummary = ({ collectionReference }) => {
    const DEFAULT_TABLE_PARAMETERS = {
        offset: 0,
        limit: 10,
        selectedProviders: [],
        selectedStatuses: [],
        selectedRegulators: [],
        selectedAwaitingAction: [],
        sortBy: 'last-submission',
        sortOrder: 'desc',
    };

    const tableId = TABLE_PARAMS_IDS.SUBMISSIONS_SUMMARY;

    const { values: tableParams, methods: tableParamsMethods } = useTableParams(
        tableId,
        DEFAULT_TABLE_PARAMETERS,
    );

    const {
        limit,
        offset,
        sortBy,
        sortOrder,
        selectedProviders,
        selectedStatuses,
        selectedRegulators,
        selectedAwaitingAction,
    } = tableParams;

    const {
        setLimit,
        setOffset,
        setSortBy,
        setSortOrder,
        setSelectedAwaitingAction,
        setSelectedRegulators,
        setSelectedProviders,
        setSelectedStatuses,
    } = tableParamsMethods;

    const { status, submissions, submissionStates } = useProviderSubmissions({
        collectionReference,
        sortBy,
        sortOrder,
    });

    const handleSortChange = id => {
        if (sortBy === id) {
            // If already sorting by the same column, flip the order
            const flippedSortOrder = sortOrder === 'desc' ? 'asc' : 'desc';
            setSortOrder(flippedSortOrder);
        } else {
            // If sorting by a different column, set the order to the default
            setSortOrder(DEFAULT_TABLE_PARAMETERS.sortOrder);
            setSortBy(id);
        }
    };

    const submissionsWithStates =
        submissions?.map(submission => ({
            ...submission,
            state: submission?.latestSubmission?.status?.id || 0,
        })) || [];

    const filteredSubmissions = filterSubmissions(
        submissionsWithStates,
        selectedProviders,
        selectedStatuses,
        selectedRegulators,
        selectedAwaitingAction,
    );

    const providers = submissions.map(s => s.provider);

    const renderContent = () => {
        switch (status) {
            case uiStates.LOADING:
                return <Loading />;

            case uiStates.LOADED:
                return (
                    <>
                        <TabPanel value={0} index={0}>
                            <Filters
                                providers={providers}
                                submissions={submissionsWithStates}
                                submissionStates={submissionStates}
                                setSelectedProviders={setSelectedProviders}
                                setSelectedAwaitingAction={
                                    setSelectedAwaitingAction
                                }
                                setSelectedRegulators={setSelectedRegulators}
                                setSelectedStatuses={setSelectedStatuses}
                                selectedProviders={selectedProviders}
                                selectedStatuses={selectedStatuses}
                                selectedRegulators={selectedRegulators}
                                selectedAwaitingAction={selectedAwaitingAction}
                            />
                            <SubmissionSummaryTable
                                submissions={filteredSubmissions}
                                order={sortOrder}
                                orderBy={sortBy}
                                onSort={handleSortChange}
                                limit={limit}
                                setLimit={setLimit}
                                offset={offset}
                                setOffset={setOffset}
                            />
                        </TabPanel>
                    </>
                );

            default:
                return null;
        }
    };

    return <>{renderContent()}</>;
};

SubmissionSummary.displayName = 'SubmissionSummary';
